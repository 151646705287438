import {useState, useEffect, useRef} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import CroppieImage from "../ui/CroppieImage";
import Select from 'react-select'
import {Accordion, AccordionItem } from '@szhsin/react-accordion';
import AccordionEditHead from "../../components/ui/AccordionEditHead";
import ProgressModal from "../ui/ProgressModal";
import NotConection from "../ui/NotConection";
import {extractDate, separarImagen} from "../utility/utils"
import {IoImages, IoChevronBack, IoToday, IoCloseCircle, IoCheckmark, IoCloudUploadOutline, IoRefreshCircle } from "react-icons/io5";
import Compress from 'compress.js'
import { useTranslation } from 'react-i18next';


export default function CancionForm({open, setOpen, isComplete, cancionId, setIdCancion, type, setMessage, handleClosed}) {
    const {online, session, loading, setLoading, urlbase, myHeaders} = useApp();
    const { t } = useTranslation();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [image, setImage] = useState("/images/caratula.jpg");
    const [image_f, setImagef] = useState(null);
    const [s_file, setSFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isFailed, setFailed] = useState(true);
    const [authors, setAuthors] = useState([]);
    const [collaborators, setCollaborators] = useState([]);
    const [selectes, setSelectes] = useState([]);
    const [selectesc, setSelectesc] = useState([]);
    const inputMp3 = useRef();
    const reader = new FileReader();
	const [song, setSong] = useState({
        title: "",
        authors:"",
        autors: [],
        collaborators: [],
        track: "",
        metadata: null,
        state: "Creada",
        creation_date: new Date(),
        song_lyrics: "",
        cover_file: ""
    });
    

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            setIdCancion(null);
            window.scrollTo(0,0);
            inputMp3.current.value = "";
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (song.title != ""){
                let formData = new FormData();
                formData.append('title', song.title);
                if (selectes.length > 0) {
                    for (let i = 0; i < selectes.length; i++) {
                        formData.append('autors[]', selectes[i]);
                    }
                }
                if (selectesc.length > 0) {
                    for (let i = 0; i < selectesc.length; i++) {
                        formData.append('collaborators[]', selectesc[i]);
                    }
                }
                formData.append('state', song.state);
                formData.append('creation_date', song.creation_date);
                formData.append('track', song.track);
                formData.append('song_lyrics', song.song_lyrics);
                formData.append('cover_file', song.cover_file);
                setLoading(true);
                setFailed(false);
                button.disabled = true;
                let Req;
                if(window.XMLHttpRequest) {
                    Req = new XMLHttpRequest();
                }
                if (cancionId) {
                    Req.open("PUT", urlbase+"/app/update_song/"+cancionId, true);
                }else{
                    Req.open("POST", urlbase+"/app/save_song", true);
                }
                Req.setRequestHeader('Access-Control-Allow-Origin', '*');
                Req.setRequestHeader('Authorization', session.token);
                Req.responseType = 'json';
                Req.upload.onprogress = function (e) {
                    if (e.lengthComputable) {
                        var ratio = Math.floor((e.loaded / e.total) * 100);
                        setProgress(ratio);
                    }
                }
                Req.upload.onerror = function (e) {
                    setFailed(true);
                    console.log("** An error occurred during the transaction");
                };
                Req.onreadystatechange = async function() {
                    if (Req.readyState === 4) {
                        const res = Req.response;
                        if(res.clave && res.clave == "exito") {
                            if (cancionId) {
                                setMessage({text: t('updated_song'), type:"done"});
                            }else{
                                setMessage({text: t('song_saved'), type:"done"});
                            }
                            setIdCancion(null);
                            setLoading(false);
                            button.disabled = false;
                            setOpen(false);
                            isComplete();
                            setSong({ title: "", authors:"", autors:[], collaborators:[], track: "", state: "Libre", creation_date: new Date(), song_lyrics: "", cover_file: ""});
                            window.scrollTo(0,0);
                        }else{
                            setAlert(res.mensaje);
                        }
                    }
                }
                if (song.track == '') {
                    setAlert(t('you_must_load_the_demo_track'));
                    setLoading(false);
                    button.disabled = false;
                }else{
                    Req.send(formData);
                }
            }else{
            	setAlert(t('you_must_add_a_creation_title'));
                setLoading(false);
                button.disabled = false;
            }
        } catch (err) {
            console.log(err);
            setAlert(t('server_error'));
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadSong = async (id) => {
    	const res = await fetch(urlbase+"/app/get_song/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.song;
        setSong({ title: data.title, collaborators:data.collaborators, autors:data.autors, authors:data.authors, state: data.state, creation_date: new Date(data.creation_date), song_lyrics:data.song_lyrics});
        if (data.cover_file) {
            setImage(urlbase+data.cover_file);
        }
        if (data.autors && data.autors.length > 0){
            const array = await data.autors.map(function(item) {
                return item['_id'];
            });
            await setSelectes(array);
        }
        if (data.collaborators && data.collaborators.length > 0){
            const array = await data.collaborators.map(function(item) {
                return item['_id'];
            });
            await setSelectesc(array);
        }
        setIsLoad(false);
    }
    const loadAuthors = async (id) => {
    	const res = await fetch(urlbase+"/app/get_authors", {headers:myHeaders()});
    	const response = await res.json();
    	const data = response;
        setAuthors(data);
    }
    const loadCollaborators = async (id) => {
    	const res = await fetch(urlbase+"/app/get_collaborators", {headers:myHeaders()});
    	const response = await res.json();
    	const data = response;
        setCollaborators(data);
    }
    const completeImage = (croppedImage) => {
        if (croppedImage) {
            setImagef(croppedImage);
        }
    }
    const handleChange = (e) => {
        setSong({...song, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleReload = (e) => {
        setProgress(0);
        handleSubmit(e);
    }
    const handleMp3 = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['track']: e.target.files[0]});
        }else{
            setSong({...song, ['track']: null});
        }
    } 
    const handleChangeFile = (e) => {
        let file = e.target.files[0];
        var compress = new Compress();
        var files = [file];
        compress.compress(files, {size: 2,quality: 0.85,maxWidth: 800,maxHeight: 800,resize: true})
        .then((images) => {
            var img = images[0];
            var imagen = img.prefix+img.data.toString();
            var file_t = separarImagen(imagen);
            if (file_t) {
                reader.onload = function (e) {
                    setImage(e.target.result);
                }
                reader.readAsDataURL(file_t);
                setSFile(true);
            }
        });
    }
    const handleMulti = (e) => {
        let arr = [];
        if (e.length > 0) {
            arr = e.map(function(item) {
                return item['value'];
            });
        }else{
            arr = [];
        }
        setSelectes(arr);
        setAlert(null);
    }
    const handleMultic = (e) => {
        let arr = [];
        if (e.length > 0) {
            arr = e.map(function(item) {
                return item['value'];
            });
        }else{
            arr = [];
        }
        setSelectesc(arr);
        setAlert(null);
    }

    useEffect(() => {
        if (open) {
            if (inputMp3 && inputMp3.current) {
                inputMp3.current.value = "";
            }
            setSong({ title: "", authors:"", autors:[], collaborators:[], track: "", state: "Libre", creation_date: new Date(), song_lyrics: "", cover_file: ""});
            loadAuthors();
            loadCollaborators();
            if (cancionId != null){
                setIsLoad(true);
                loadSong(cancionId);
            }else{
                setImage("/images/caratula.jpg");
                setIsLoad(false);
            }
        }
    }, [open]);


    useEffect(() => {
        if (s_file) {
            setSong({...song, ['cover_file']: image_f});
        }
    }, [image_f]);


    if (!online) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <NotConection handleClosed={handleBack}/>
            </div>
        </div>
    </div>;

    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>{cancionId != null ? t('edit_creation') : t('new_creation')}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row w-100">
                    {open ? <Accordion className='accordion cl-mg-top w-100' transition={true} transitionTimeout={250}>
                        <AccordionItem header={<AccordionEditHead titulo={t('poster_of_the_song')} initialEntered={false}/>} >
                            {({state}) => (<>
                                <div className="row w-100">
                                    {state.isEnter ? <CroppieImage open={state.isEnter} image={image} aspect={4 / 4} getCompleteCorp={completeImage} /> : ''}
                                    <div className="row my-1 text-center w-100">
                                        <button className="btn-icon btn-sm w-100 btn-primary waves-effect waves-light">
                                            <input type="file" className="file-in-button" onChange={handleChangeFile} accept="image/*"/>
                                            <div className="row w-100 align-items-center text-center">
                                                <IoImages className="icon-btn mr-m" />
                                                <span className="col"> {t('choose')} </span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </>)}
                        </AccordionItem>
                    </Accordion> : ''}
                    <div className="row w-100 my-1">
                        <div className="row my-1 w-100">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>{t('track')}</span>
                                    {cancionId ? '' : <span className='tg-requir'>{t('required')}</span>}
                                </div>
                            </label>
                            <input type="file" className={ cancionId ? "inp_add" : "inp_add in-requi" } ref={inputMp3} onInput={handleMp3} name="track" accept=".mp3,.m4a,.wav,audio/"/>
                        </div>
                        <div className="row w-100">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>{t('title')}</span>
                                    <span className='tg-requir'>{t('required')}</span>
                                </div>
                            </label>
                            <input type="text" className="inp_add in-requi" value={song.title} onChange={handleChange} name="title" placeholder={t('title') + " *"} autoComplete="off"/>
                        </div>
                        <div className="row w-100 my-1">
                            <label className="lb-inp-usm mb-1">{t('author')}</label>
                            {cancionId != null && song.autors && song.autors.length > 0 ? <Select className='multi_sel w-100'
                                name="autors"
                                placeholder={t('choose')}
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                onChange={handleMulti}
                                defaultValue={song.autors.map((sng) => {return {value: sng._id, label:sng.stage_name}})}
                                options={authors.map((author, ind) => {
                                    return {value:author._id, label:author.stage_name}
                                })}
                            /> : <Select className='multi_sel w-100'
                                    name="autors"
                                    placeholder={t('choose')}
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti
                                    onChange={handleMulti}
                                    options={authors.map((author, ind) => {
                                        return {value:author._id, label:author.stage_name}
                                    })}
                                />}
                        </div>
                        <div className="row w-100 my-1">
                            <label className="lb-inp-usm mb-1">Colaborador</label>
                            {cancionId != null && song.collaborators && song.collaborators.length > 0 ? <Select className='multi_sel w-100'
                                name="collaborators"
                                placeholder={t('choose')}
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                onChange={handleMultic}
                                defaultValue={song.collaborators.map((sng) => {return {value: sng._id, label:sng.name}})}
                                options={collaborators.map((stdy, ind) => {
                                    return {value:stdy._id, label:stdy.name}
                                })}
                            /> : <Select className='multi_sel w-100'
                                    name="collaborators"
                                    placeholder={t('choose')}
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti
                                    onChange={handleMultic}
                                    options={collaborators.map((stdy, ind) => {
                                        return {value:stdy._id, label:stdy.name}
                                    })}
                                />}
                            <p className="py-1 sm-text color-secondary">El colaborador podra ver la cancion en su app de songor</p>
                        </div>
                        <div className="row w-100">
                            <label className="lb-inp-usm mb-1">{t('creation_date')}</label>
                            <div className='position-relative w-100'>
                                <input type="date" className="inp_add inpd_m" defaultValue={extractDate(song.creation_date)} onInput={handleChange} name="creation_date"/>
                                <IoToday className="icn_fl_sel icon-sm showMovil" />
                            </div>
                        </div>
                        <div className="row w-100 my-1">
                            <label className="lb-inp-usm mb-1">{t('lyrics')}</label>
                            <textarea className="inp_add h-200" onChange={handleChange} value={song.song_lyrics} name="song_lyrics" placeholder={t('lyrics_of_the_song')}></textarea>
                        </div>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{ loading ? t('uploading') : cancionId ? t('apply') : t('create')}</button>
                    </div>
                </div>
            </div>
            { loading ? <div className='fixed-upload'>
                <div className='center-content'>
                    <div className="row w-100">
                        <div className='row w-100 text-center justify-center p-3'>
                            <IoCloudUploadOutline className="icon-upld-anim"/>
                        </div>
                        <div className='row w-100 p-2'></div>
                        <div className="row w-100 cont_downloaad">
                            <div className="img_inl text-center">
                                <img src={'/images/caratula.jpg'} className="img-profile-med" />
                            </div>
                            <div className="text_inl text-left">
                                <p className="tag_tt_song clear-my">{t('uploading')}</p>
                                <p className="stx_tt text-secondary sm-text clear-my mb-1">{t('audio_track_and_poster')}</p>
                            </div>
                            <div className="row w-100 text-center">
                                <div className='barr_gains showBarr w-100'>
                                    <div className={isFailed ? "line_progrs_int brr_red" : progress >= 100 ? "line_progrs_int brr_grren" : "line_progrs_int"} style={{width:progress+'%'}}></div>
                                </div>
                            </div>
                            {isFailed ?  <span className="isDownloafail"><IoCloseCircle className="checkfail"/></span> : progress >= 100 ? <span className="isDownloaded">
                                <IoCheckmark className="checkisdone"/>
                            </span> : ''}
                            {progress < 100 ? <span className="isDownloaded"><div className="spinner_sm"></div></span> : ''}
                        </div>
                        {isFailed ? <div className={"d-flex mt-1 w-100 justify-between justify-items-center bg-danger-light rounded-4"}>
                            <p className="text-message p-2">{t('failed_uploading_files')}</p>
                            <IoRefreshCircle className="reload-button p-2 waves-effect waves-light" onClick={handleReload}/>
                        </div> : ''}
                        <div className='row w-100 text-center p-3'>
                            <p className="stx_tt text-secondary sm-text clear-my mb-1">{t('not_close_this_window')}</p>
                        </div>
                    </div>
                </div>
            </div> : ''}
        </div>
	)
}
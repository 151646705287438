import {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function MovilMenu({open, items, children, animation, online}){
    let [elements, setElements] = useState([]);
    const [os, setOS] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        setElements(items);
        if (/MacIntel|iPhone|iPad|iPod/.test(window.navigator.platform) && window.navigator.maxTouchPoints > 1) {
            setOS("iOS");
        } else if(/Android/i.test(window.navigator.userAgent)) {
            setOS("Android");
        } else{
            setOS("Otro");
        }
    }, []);

    return(
        <div className={"fixed-bottom-menu bg-light"} id="menu_slide">
            <div className="position-sticky">
                {children}
                <ul className={items && items.length == 5 ? "wrapper-5 justify-center" : "wrapper-4 justify-center"}>
                    {items.map((item, ind) => {
                        return (
                            <NavLink key={'men_'+ind} to={item.url} className={({isActive}) => (isActive ? 'waves-effect nav-link-m isActive' : 'waves-effect nav-link-m')}>
                                {item.icon}
                                <span className="tex_hed_m">{item.name}</span>
                            </NavLink>
                        )
                    })}
                </ul>
                {os && os == 'iOS' ? <div className='w-100 p-menu-mov'></div> : ''}
                {online ? '' : (<div className='no-inline'>{t('offline_mode')}</div>) }
            </div>
        </div>
    )
}
import MovilMenu from "../components/ui/MovilMenu";
import TrackerPlayer from "../components/ui/TrackerPlayerCustom";
import Header from "../components/ui/Header";
import SideMenu from "../components/ui/SideMenu";
import ProtectedAdmin from "../components/protected/ProtectedAdmin"
import {IoReceipt , IoApps, IoPerson, IoMusicalNotesSharp, IoListSharp, IoPeopleSharp, IoPersonCircleSharp, IoPersonAddSharp, IoSend, IoLayers, IoPeopleCircleSharp } from "react-icons/io5";
import { MdHomeFilled } from "react-icons/md";
import {useApp} from "../context/appContext";
import {Outlet} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function Dashboard({children}) {
    const {session, online, playlist, setPlaylist} = useApp();
    const { t } = useTranslation();

    let items = [
        {name:t('home'), url:'/app/tablon', icon:(<MdHomeFilled className="icon_it_he fs-hm"/>)},
        {name:t('sent'), url:'/app/enviadas', icon:(<IoSend  className="icon_it_he"/>)},
        {name:t('created'), url:'/app/creaciones', icon:(<IoMusicalNotesSharp  className="icon_it_he"/>)},
        {name:t('catalog'), url:'/app/catalogo', icon:(<IoListSharp className="icon_it_he"/>)},
        {name:t('clients'), url:'/app/clientes', icon:(<IoPerson className="icon_it_he"/>)},
        {name:t('artists'), url:'/app/artistas', icon:(<IoPersonCircleSharp  className="icon_it_he"/>)},
        {name:t('authors_and'), url:'/app/autores', icon:(<IoPersonAddSharp className="icon_it_he"/>)},
        {name:t('editors'), type:'app', url:'/app/editoras', icon:(<IoReceipt className="icon_it_he"/>)},
        {name:t('users'), url:'/app/usuarios', icon:(<IoPeopleCircleSharp  className="icon_it_he"/>)},
        {name:t('records'), url:'/app/listas_de_registros', icon:(<IoLayers className="icon_it_he"/>)}
    ];
    let itemsM = [
        {name:t('home'), url:'/app/tablon', icon:(<MdHomeFilled className="icon_it_mv fs-hm-m"/>)},
        {name:t('created'), url:'/app/creaciones', icon:(<IoMusicalNotesSharp  className="icon_it_mv"/>)},
        {name:t('catalog'), url:'/app/catalogo', icon:(<IoListSharp className="icon_it_mv"/>)},
        {name:t('clients'), url:'/app/clientes', icon:(<IoPerson className="icon_it_mv"/>)},
        {name:t('more'), url:'/app/more_options', icon:(<IoApps className="icon_it_mv"/>)}
    ];
    

    return(
        <>
            <ProtectedAdmin>
                <Header></Header>
                <div className="d-flex">
                <div className="py-1"></div>
                    <SideMenu items={items} id_coll={session && session.user ? session.user.study.id_collaboration : null}>
                    </SideMenu>
                    <main className="container_main col-md-12 col-lg-10 ms-sm-auto px-md-4 fadeIn">
                        <div className="p-2 md-p-2">
                            <Outlet />
                        </div>
                        {playlist && playlist.length > 0 ? <TrackerPlayer playlist={playlist} setPlaylist={setPlaylist}/> : ''}
                    </main>
                </div>
                <MovilMenu items={itemsM} online={online}></MovilMenu>
            </ProtectedAdmin>
        </>
    )
}



        
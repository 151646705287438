import { useNavigate } from "react-router-dom";
import {useRef, useState} from 'react'
import {useApp} from "../context/appContext";
import { StatusBar } from '@capacitor/status-bar';
import { useTranslation } from 'react-i18next';
import {updateThemeColor} from "../components/utility/utils";
import LoginForm from "../components/forms/LoginForm";
import SignupForm from "../components/forms/SignupForm";
import ForgotForm from "../components/forms/ForgotForm";

export default function Login() {
    const {loading, setLoading, urlbase, loadSessionToken} = useApp();
    const { t } = useTranslation();
    const[portal, setPortal] = useState("login");
    const [alert, setAlert ] = useState(null);
    const [showintro, setShowIntro ] = useState(false);
    const navigate = useNavigate();

    const updateStatusBarColor = (color) => {
        // Cambia la metaetiqueta theme-color
        document.querySelector("meta[name=theme-color]")?.setAttribute("content", color);
        // Cambia el color de la barra de estado en Capacitor
        StatusBar.setBackgroundColor({ color: color });
    };

    updateStatusBarColor('#ffffff');

    return (
        <div className="landing_ani fadeIn position-relative">
            {showintro == true ? <div className="fixed-login-content Isexpand">
                <div className='center-content text-center'>
                    <img className="img_tit_log" src="/images/icon.png" />
                    <div className="row w-100 text-center justify-center py-1">
                        <div className="sippiner"></div>
                    </div>
                    <span>{t('logging_in')}...</span>
                </div>
            </div>: ''}
            <div className="row content-central mt-3">
                <div className="col col-md-12 position-relative hg-md-200 ocultar-sm">
                    <div className="opacy_login"></div>
                    <img src="/images/controles.jpg" className="bg-image" />
                </div>
                <div className="col col-md-12">
                    {portal == 'login' ? <LoginForm setPortal={setPortal} setShowIntro={setShowIntro} portal={portal} /> : portal == 'forgot' ? <ForgotForm setPortal={setPortal} /> : <SignupForm setPortal={setPortal} />}
                </div>
            </div>  
            <div className="footer-login">
                <div className="text-center text-secondary w-100">
                    <div>Terminos y Condiciones</div>
                    <div className="py-1 w-100 opc-3">{t('all_rights_reserved')} © songor {new Date().getFullYear()}</div>
                </div>
            </div>
        </div>
    )
}
import {IoCopyOutline, IoChevronForwardOutline} from "react-icons/io5";
import {useState, useEffect, useRef} from 'react'
import {Link } from "react-router-dom";
import {useApp} from "../../context/appContext";
import Modal from "../../components/ui/Modal";
import Song from "../../components/views/Song";
import {extractHour, extractDateFormatR} from "../../components/utility/utils"
import {IoChevronForward, IoClose, IoCheckmark} from "react-icons/io5";
import { getCreaciones, getLibres, getApartadas, getGrabadas } from "../../db";
import { useTranslation } from 'react-i18next';
import SnackBar from "../../components/ui/SnackBar";

export default function Tablon(){
    const {setNColl, online, session, setLoading, urlbase, myHeaders} = useApp();
    const { t } = useTranslation();
    const [message, setMessage] = useState(false);
    const [access, setAccess] = useState([]);
    const [sends, setSends] = useState([]);
    const [songs, setSongs] = useState([]);
    const [opens, setOpenes ] = useState(false);
    const [idview, setIdView] = useState(null);
    const textRef = useRef(null);
    const [statistics, setStatistics] = useState({
        creaciones: 0,
        libres: 0,
        apartadas: 0,
        grabadas: 0
    });

    const handleCopy = (event) => {
        const id = event.target.id; // Obtener el id del elemento clickeado
        window.navigator.clipboard.writeText(id)
        .then(() => setMessage({text: "copiado", type:"done"}))
        .catch((err) => console.error("Error al copiar: ", err));
    };
    const handleSelection = (event) => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(textRef.current); // Selecciona el contenido del elemento
        selection.removeAllRanges(); // Elimina cualquier selección previa
        selection.addRange(range); // Agrega la nueva selección
    };
    const handleClosed = (e) => {
        setOpenes(false);
        setIdView(null);
        e.stopPropagation();
    }
    const loadStatistics = async (date) => {
        if (online) {
            try {
                setLoading(true);
                const response = await fetch(urlbase+"/app/get_statistics", {
                    method: 'GET',
                    headers: myHeaders(),
                })
                const data = await response.json();
                const access = data.accesos;
                const sends = data.eviados;
                const ncolls = data.datos.ncolls;
                setNColl(ncolls);
                setStatistics(data.datos);
                if (access && access.length > 0) {
                    setAccess(access);
                }
                if (sends && sends.length > 0) {
                    setSends(sends);
                }
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }else{
            const t_creaciones = await getCreaciones();
            const t_libres = await getLibres();
            const t_apartadas = await getApartadas();
            const t_grabadas = await getGrabadas();
            setStatistics({
                creaciones: t_creaciones.length,
                libres: t_libres.length,
                apartadas: t_apartadas.length,
                grabadas: t_grabadas.length
            });
            setLoading(false);
        }
    }
    const handleView = async (e) => {
        setIdView(e.currentTarget.id);
        setOpenes(true);
    }

    useEffect(() => {
        loadStatistics();
    }, []);



    return(
        <div className="Isexpand sm-py-2">
            {session && session.user ?
                <div className="showMovil w-100 py-1">
                    <div className="row justify-between align-items-center px-3">
                        <div className="col"><strong className="text-secondary">Id Songor</strong></div>
                        <div className="col text-right">
                            <div className="row align-items-center w-100 justify-end">
                                <span className="sm_is_link" ref={textRef} onClick={handleSelection}>{session.user.study.id_collaboration }</span><IoCopyOutline className='mx-1 icon-cp-s' id={session.user.study.id_collaboration } onClick={handleCopy}/>
                            </div>
                        </div> 
                    </div> 
                </div> 
            : ''}
            <div className="row my-2 w-100">
                <div className="wrapper-4 sm-wrapper-2 w-100 px-3">
                    {session && session.user.rol != 'Redactor' ? <div className="card-desin-t max-h-185">
                        <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                        <h1 className="number_tt py-1">{statistics.creaciones}</h1>
                        <Link className="row is_link w-100 justify-center align-items-center" to="/app/creaciones">
                            <h2 className="title-crd-t clear-m-p">{t('created')}</h2>
                            <IoChevronForwardOutline className="icon-md align-icon"/>
                        </Link>
                    </div> : ''}
                    <div className="card-desin-t max-h-185">
                        <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                        <h1 className="number_tt py-1">{statistics.libres}</h1>
                        <Link className="row w-100 is_link justify-center align-items-center" to="/app/catalogo/libres">
                            <h2 className="title-crd-t clear-m-p">{t('free')} </h2>
                            <IoChevronForwardOutline className="icon-md align-icon"/>
                        </Link>
                    </div>
                    <div className="card-desin-t max-h-185">
                        <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                        <h1 className="number_tt py-1">{statistics.apartadas}</h1>
                        <Link className="row w-100 is_link justify-center align-items-center" to={session && session.user.rol != 'Redactor' ? "/app/catalogo/apartadas" : "/editor/catalogo/apartadas"}>
                            <h2 className="title-crd-t clear-m-p">{t('apart')}</h2>
                            <IoChevronForwardOutline className="icon-md align-icon"/>
                        </Link>
                    </div>
                    <div className="card-desin-t max-h-185">
                        <img src="/images/logo_iso.png" className="icon-tablon-img"/>
                        <h1 className="number_tt py-1">{statistics.grabadas}</h1>
                        <Link className="row w-100 is_link justify-center align-items-center" to={session && session.user.rol != 'Redactor' ? "/app/catalogo/vendidas" : "/editor/catalogo/vendidas"}>
                            <h2 className="title-crd-t clear-m-p">{t('recorded')}</h2>
                            <IoChevronForwardOutline className="icon-md align-icon"/>
                        </Link>
                    </div>
                </div>  
            </div>
            {session && session.user.rol != 'Redactor' ? <div className="row px-3 gap-3">
                <div className="col col-sm-12 bg-white border-text-black-50 rounded">
                    <div className="row w-100">
                        <div className="row w-100 px-3">
                            <h3 className="my-1 py-1 border-bottom w-100">{t('recently_sent')}</h3>
                            <table className="table_outline">
                                <thead>
                                    <tr>
                                        <th className="text-center ocultar-sm">{t('folio')}</th>
                                        <th>{t('artist')}</th>
                                        <th className="text-right">{t('sent_t')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sends.map((send, ind) => {
                                        if (send.song && send.song._id && send.client) {
                                            return(<tr key={'t_u_'+ind}>
                                                <td className="text-center ocultar-sm">
                                                    {send.song && send.song.folio_reg ? send.song.folio_reg : '-'}
                                                </td>
                                                <td>
                                                    <div className="row gap-1 justify-items-center">
                                                        <div className="col text-left">
                                                            <p className="tag_inl is_link clear-my" id={send.song._id} onClick={handleView}>{send.song ? send.song.title : '-'}</p>
                                                            <p className="text-secondary sm-text-75 clear-my">
                                                                <div className="row w-100 justify-items-center">
                                                                    <span>{send.client ? send.client.name : '-'}</span>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    <span className="sm-text w-100">{extractDateFormatR(send.created)}</span>
                                                    <p className="w-100 sm-text color-secondary w-100">{extractHour(send.created)}</p>
                                                </td>
                                            </tr>)
                                        }
                                    })}
                                </tbody>
                            </table>
                            <Link to={'/app/enviadas'} className="row w-100 py-2 is_link justify-center justify-items-center">
                                <span className="col-1 text-center mr-1">{t('see_all')}</span>
                                <IoChevronForward className="col-1 icon-sm"/>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col col-sm-12 md-p-2 bg-white border-text-black-50 rounded">
                    <div className="row w-100">
                        <div className="row w-100 px-3">
                            <h3 className="my-1 py-1 border-bottom w-100">{t('access_to_the_app')}</h3>
                            <table className="table_outline">
                                <tbody>
                                    {access.map((acc) => {
                                        let d = new Date(acc.date_in);
                                        return(
                                            <tr key={acc._id}>
                                                <td className="ocultar-sm">
                                                    <span className="ocultar-md text-secondary fs-85">{extractDateFormatR(d)+" "+extractHour(d)}</span> 
                                                </td>
                                                <td>
                                                    {acc.user != null ? <div className="row gap-1 justify-items-center">
                                                        <div className="col-2 mr-1">
                                                            <img src="/images/usuario.png" className="img-profile-sm" />
                                                        </div>
                                                        <div className="col text-left">
                                                            <p className="tag_inl is_link clear-my">{acc.user.name} {acc.user.lastname}</p>
                                                            <p className="stx_tt text-secondary sm-text ocultar-sm clear-my">{acc.user.rol}</p>
                                                            <span className="showMovil text-secondary fs-85">{extractDateFormatR(d)+" "+extractHour(d)}</span>
                                                        </div>
                                                    </div> :
                                                    <p className="is_link clear-my">{t('unknown')}</p>}
                                                </td>
                                                <td className="ocultar-sm">
                                                    <div className="limpio">
                                                        {acc.login ? 
                                                            <span className="mx-1 sm-text tag-done sm-text ocultar-sm"><IoCheckmark className="icon-sm align-icon"/></span> 
                                                            : <span className="mx-1 sm-text tag-fail sm-text ocultar-sm"><IoClose className="icon-sm align-icon"/></span>}
                                                    </div>
                                                </td>
                                                <td className="ocultar-sm">
                                                    <div className="limpio">
                                                        <span className="text-secondary ocultar-md fs-sm">{t('user')}</span>
                                                        {acc.login ? 
                                                            <span className="mx-1 ocultar-sm">
                                                                <IoCheckmark className="icon-sm align-icon"/>
                                                            </span> 
                                                            : <span className="mx-1 text-sm ocultar-sm"><IoClose className="icon-sm align-icon"/> {acc.code}</span>}
                                                    </div>
                                                </td>
                                                <td className="ocultar-sm">
                                                    <div className="limpio">
                                                        <span className="text-secondary ocultar-md fs-sm">{t('password')}</span>
                                                        {acc.login ? 
                                                            <span className="mx-1 ocultar-sm">
                                                                <IoCheckmark className="icon-sm align-icon"/>
                                                            </span> 
                                                            : <span className="mx-1 text-sm ocultar-sm"><IoClose className="icon-sm align-icon"/> {acc.password}</span>}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="row w-100 py-2 is_link justify-center justify-items-center">
                                <span className="col-1 text-center mr-1">{t('see_all')}</span>
                                <IoChevronForward className="col-1 icon-sm"/>
                            </div>
                        </div>  
                    </div>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
            </div> : ''}
            <Modal open={opens} setOpen={setOpenes} size={"modal-md"} height={"hg-auto"} handleClosed={handleClosed}>
                <Song open={opens} setOpen={setOpenes} songId={idview}/>
            </Modal>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}
import {useEffect, createContext, useContext, useState} from 'react'
import ProgressBar from "../components/ui/ProgressBar";

export const appContext = createContext();
export const useApp = () => {
	const context = useContext(appContext);
	return context;
}

export function AppProvider ({children}) {
	const [onload, setOnLoad] = useState(true);
	const [loading, setLoading] = useState(false);
	const [urlbase, setUrlBase] = useState(null);
	const [session, setSession] = useState(null);
	const [playlist, setPlaylist] = useState([]);
	const [position, setPosition] = useState(1);
	const [ncoll, setNColl] = useState(0);
	const [online, setOnline] = useState(window.navigator.onLine); // Inicializar con el estado actual de la conexión
	const [play, setPlay] = useState(false);

	const isTokenExpired = (token) => {
		// console.log(!token);
		// if (!token) return true; // Si no hay token, considerarlo expirado
		const payloadBase64 = token.split('.')[1];
		try {
		  const decodedPayload = JSON.parse(atob(payloadBase64));
		  const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
		  return decodedPayload.exp < currentTime; // Comparar tiempo actual con la expiración
		} catch (error) {
		  console.error("Error al decodificar el token", error);
		  return true; // Si el token no es válido, considerarlo expirado
		}
	};
	const loadSessionToken = (auth) => {
		if (auth && auth.token) {
			localStorage.setItem('session', JSON.stringify(auth));
			setSession(auth);
			return auth;
		}else{
			let ses = JSON.parse(localStorage.getItem('session'));
			if (ses) {
				if (isTokenExpired(ses.token)) {
					clearSession();
				}else{
					setSession(ses);
					return ses;
				}
			}else{
				setSession(null);
				return ses;
			}
		}
	}
	const clearSession = () => {
		localStorage.removeItem("session");
		setSession(null);
	}
	const myHeaders = (file) => {
		let headers;
		if (file) {
			headers = {
				'Access-Control-Allow-Origin': '*',
	            'Authorization': ""+session.token
			}
		}else{
			headers = {
				'Access-Control-Allow-Origin': '*',
	            'Content-Type': "application/json",
	            'Authorization': ""+session.token
			}
		}
		return headers;
	}


	useEffect(() => {
		setOnLoad(false);
		setUrlBase(window.location.protocol+"//"+window.location.hostname);
		loadSessionToken();
		// Manejar eventos online y offline
		const updateOnlineStatus = () => {
			setOnline(window.navigator.onLine);
		};
		window.addEventListener('online', updateOnlineStatus);
		window.addEventListener('offline', updateOnlineStatus);
		return () => {
			window.removeEventListener('online', updateOnlineStatus);
			window.removeEventListener('offline', updateOnlineStatus);
		};
	}, [])

	return (
		<appContext.Provider value={{
			onload, 
			loading, 
			setLoading, 
			urlbase, 
			loadSessionToken, 
			session, 
			myHeaders, 
			clearSession, 
			play, 
			setPlay, 
			playlist, 
			setPlaylist, 
			setPosition, 
			position, 
			online,
			setNColl,
			ncoll
			}}>
			{loading ? (<div className="fixed-top-barr"><ProgressBar></ProgressBar></div>) : ''}
			{children}
		</appContext.Provider>
	)
}
import { useEffect, useState } from "react";
import {IoChevronBack, IoCheckmark, IoShareSocialSharp, IoCaretDownOutline, IoCloseCircle, IoArrowDownSharp, IoRefreshCircle } from "react-icons/io5";
import ProgressModal from "./ProgressModal";
import {useApp} from "../../context/appContext";
import {limitText, getBlobExtension} from "../utility/utils"
import { getSongFromIndexedDB } from "../../db";
import Select from 'react-select'
import { useTranslation } from 'react-i18next';


export default function DownloadFile({songref, open, setOpen, setMessage}){
    const {urlbase, session, myHeaders} = useApp();
    const { t } = useTranslation();
    const [progress, setProgress] = useState(0);
    const [tofile, setToFile] = useState(null);
    const [isload, setIsLoad] = useState(true);
    const [isFailed, setFailed] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [textArtist, setTextArtist] = useState(t('artist_to_send'));
    const [filename, setFilename] = useState('');
    const [filetype, setFiletype] = useState('');
    const [s_client, setSClient] = useState('');
    const [clients, setClients] = useState([]);
    const [song, setSong] = useState({
        _id: '',
        title:'',
        cover_file:'',
        path: '',
        state: ''
    });
    

    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
        }, 200);
    }
    const loadClients = async (id) => {
    	const response = await fetch(urlbase+"/app/get_clients", {headers:myHeaders()});
    	const data = await response.json();
        setClients(data);
    }
    const downloadSong = async (id , title) => {
        try {
            let armId = songref._id+songref.state;
            if (songref.state == 'Apartada') {
                armId = songref._id+'Libre';
            }
            const songBlob = await getSongFromIndexedDB(armId);
            setFailed(false);
            setProgress(0);
            if (songBlob) {
                // La descraga esta en local
                let fileName = songref.title+"."+getBlobExtension(songBlob);
                setFilename(fileName);
                setFiletype(songBlob.type);
                setToFile(songBlob);
                setProgress(100);
            }else{
                // La descraga no esta en local
                let Req;
                if(window.XMLHttpRequest) {
                    Req = new XMLHttpRequest();
                }
                Req.open("GET", urlbase+"/app/get_file/"+id, true);
                Req.responseType = "blob";
                Req.setRequestHeader('Access-Control-Allow-Origin', '*');
                Req.setRequestHeader('Authorization', session.token);
                Req.onprogress = function (e) {
                    if (e.lengthComputable) {
                        var ratio = Math.floor((e.loaded / e.total) * 100);
                        setProgress(ratio);
                    }
                }
                Req.onerror = function (e) {
                    setFailed(true);
                    console.log("** An error occurred during the transaction");
                };
                Req.onreadystatechange = async function() {
                    if (Req.readyState === 4) {
                        let type = Req.getResponseHeader('content-type');
                        if (songref.state == 'Creada') {
                            var ext = songref.track.substring(songref.track.indexOf('.'));
                        }else if (songref.state == 'Libre' || songref.state == 'Apartada') {
                            var ext = songref.demo_track.substring(songref.demo_track.indexOf('.'));
                        }else{
                            var ext = songref.master_recording.substring(songref.master_recording.indexOf('.'));
                        }
                        var fileName = songref.title+ext;
                        var blob = new Blob([Req.response], {type: type});
                        setFilename(fileName);
                        setFiletype(type);
                        setToFile(blob);
                    }
                }
                Req.send();
            }
            
        } catch (err) {
            console.log(err);
        }
    }
    const handleShare = async (e) => {
        const file = new File([tofile], filename, {name:song.title, type: filetype});
        shareFILE(file);
        let button = e.currentTarget;
        try {
            if (s_client != '') {
                // setLoading(true);
                button.disabled = true;
                let data = {
                    song:song._id,
                    client: s_client,
                    state: song.state
                }
                let response = await fetch(urlbase+"/app/add_sharing_song/"+song._id, {
                    method: 'PUT',
                    headers: myHeaders(),
                    body: JSON.stringify(data)
                });
                const res = await response.json();
                if(res.clave && res.clave == "exito") {
                    setTextArtist(t('choose_a_new_artist_to_share'));
                    setSClient('');
                    // setMessage({text: "Ahora puede elegir un nuevo artista para enviar", type:"done"});
                    console.log("sharing guardado");
                }else{
                    console.log("No se pudo guardar el sharing");
                }
                // setLoading(false);
                button.disabled = false;
            }
        } catch(e) {
            // statements
            console.log(e);
        }
    }
    const handleDownload = async (e) => {
        var url = window.URL.createObjectURL(tofile);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again
        setMessage({text: filename + t('downloaded')+"!", type:"done"});
    }
    const shareFILE = async (file) => {
        try {
            const shareData = {
                files: [file],
            };
            if (navigator.canShare && navigator.canShare(shareData)) {
                let process = await navigator.share(shareData);
            } else {
                console.log('Tu navegador no admite compartir archivos.');
            }
        } catch (error) {
            console.log('Error al compartir el archivo:', error);
        }
    }
    const handleReload = (e) => {
        downloadSong(song._id, song.title);
    }
    const handleMulti = async (e) => {
        setSClient(e.value);
        if (e.value != '') {
            const res = await fetch(urlbase+"/app/comprobar_send/"+songref._id+"/"+e.value, {headers:myHeaders()});
    	    const response = await res.json();
            if (response.clave == 'fallo') {
                alert("¡"+t('song_already_sent')+"!");
            }
        }
    }

    useEffect(() => {
        if (open) {
            setSong({
                _id: songref._id,
                title: songref.title,
                cover_file: songref.cover_file,
                path: songref.track,
                state: songref.state
            });
            loadClients();
            setTimeout(() => {
                setIsLoad(false);
                downloadSong(songref._id, songref.title);
            }, 500);
        }else{
            setIsLoad(true);
            setFilename('');
            setFiletype('');
            setSong({
                _id: '',
                title:'',
                cover_file:'',
                path: '',
                state: ''
            });
            setTextArtist('Artista a enviar');
            setSClient('');
            setFailed(false);
        }
    }, [open]);


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


    return(
        <div>
             <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className='modal-tittle'>{t('share')}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row w-100">
                    <label className="lb-inp-usm mb-1 w-100 text-center">{t('file_to_share')}</label>
                    <div className="row w-100 cont_downloaad">
                        <div className="img_inl text-center">
                            <img src={'/images/caratula.jpg'} className="img-profile-med" />
                        </div>
                        <div className="text_inl text-left">
                            <p className="tag_tt_song clear-my"> {filename != '' ? limitText(filename, 20) : song.title} </p>
                            <p className="stx_tt text-secondary sm-text clear-my mb-1">{progress >= 100 ? t('ready_to_share') : t('downloading')+'...'}</p>
                        </div>
                        <div className="row w-100 text-center">
                            <div className='barr_gains showBarr w-100'>
                                <div className={isFailed ? "line_progrs_int brr_red" : progress >= 100 ? "line_progrs_int brr_grren" : "line_progrs_int"} style={{width:progress+'%'}}></div>
                            </div>
                        </div>
                        {isFailed ?  <span className="isDownloafail"><IoCloseCircle className="checkfail"/></span> : progress >= 100 ? <span className="isDownloaded">
                            <IoCheckmark className="checkisdone"/>
                        </span> : ''}
                        {progress < 100 ? <span className="isDownloaded"><div className="spinner_sm"></div></span> : ''}
                    </div>
                </div>
                {isFailed ? <div className={"d-flex mt-1 w-100 justify-between justify-items-center bg-danger-light rounded-4"}>
                    <p className="text-message p-2">{t('failed_uploading_files')}</p>
                    <IoRefreshCircle className="reload-button p-2 waves-effect waves-light" onClick={handleReload}/>
                </div> : ''}
                <div className="row w-100 justify-center py-3">
                    <label className="lb-inp-usm mb-1 w-100 text-center">{textArtist}</label>
                    <div className='position-relative w-100'>
                        <Select className='multi_sel'
                            name="requested"
                            placeholder={t('choose')}
                            isClearable={true}
                            isSearchable={true}
                            onChange={handleMulti}
                            options={clients.map((client, ind) => {
                                return {value:client._id, label:client.name}
                            })}
                            />
                    </div>
                </div>
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <p className="w-100 sm-text text-center text-secondary mb-1">{t('clicking_share_artist')}</p>
                <div className="row gap-1">
                    <div className="col col-sm-12">
                        <button className="btn btn-mv-hg btn-outline-primary waves-effect waves-light w-100" disabled={progress >= 100 ? false : true} onClick={handleDownload}>
                            {progress >= 100 ? <div className="row w-100 align-items-center">
                                <IoArrowDownSharp className="icon-btn mr-m ms-sm-rauto sm-txt-18" />
                                <span className="col text-uppercase">{t('download')}</span>
                            </div> : t('preparing_track')}
                        </button>
                    </div>
                    <div className="col col-sm-12">
                        <button className="btn btn-mv-hg btn-primary waves-effect waves-light w-100" disabled={progress >= 100 ? false : true} onClick={handleShare}>
                            {progress >= 100 ? <div className="row w-100 align-items-center">
                                <IoShareSocialSharp className="icon-btn mr-m ms-sm-rauto sm-txt-18" />
                                <span className="col text-uppercase">{t('share')}</span>
                            </div> : t('preparing_track')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
    )
}
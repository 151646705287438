import {useState} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {validEmail} from "../../components/utility/utils";
import { useTranslation } from 'react-i18next';
import {IoChevronBack, IoMail, IoCheckmarkSharp} from "react-icons/io5";

export default function ForgotForm({setPortal}) {
    const {urlbase, myHeaders} = useApp();
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [alert, setAlert ] = useState(null);
    const [loading, setLoading ] = useState(false);
    const [complete, setComplete] = useState(false);
    const [user, setUser] = useState({
        email: ""
    });

    console.log(currentLanguage);

    const handlePortal = () => {
        setPortal("login");
    }
    const handleChange = (e) => {
        if (validEmail(e.target.value)) {
            setAlert(null);
            setUser({...user, [e.target.name]: e.target.value});
        }else{
            setAlert(t('invalid_mail'));
            setUser({...user, [e.target.name]: e.target.value});
        }
    }
    const handleForgot = async (e) => {
        if(validEmail(user.email)){
            let button = e.currentTarget;
            try {
                e.preventDefault();
                button.disabled = true;
                setLoading(true);
                let response = await fetch(urlbase+"/forgot_password", {
                    method: 'POST',
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({user:user, lang:currentLanguage})
                });
                const res = await response.json();
                if (res.clave == 'exito') {
                    setComplete(true);
                }else{
                    setAlert(t('account_no_register'));
                }
                setUser({email:''});
                setLoading(false);
                button.disabled = false;
            } catch(e) {
                // statements
                console.log(e);
                setAlert(t('server_error'));
                button.disabled = false;
                setLoading(false);
                setUser({email:''});
            }
        }else{
            setAlert(t('invalid_mail'));
        }
    }
    const stepSignup = async (e) => {
        setPortal("signup");
    }
    const aceptForgot = async (e) => {
        setPortal("login");
    }



    return (
        <div>
            <div className="Isexpand cont_signup py-0">
                <div className="contene_log" id="cont_login">
                    <div className="row justify-between align-items-center cnt_inp">
                        <button className="btn-link-lg" onClick={handlePortal}>
                            <div className="row align-items-center">
                                <IoChevronBack className="lg-icn-face" />
                                {t('login')}
                            </div>
                        </button>
                        <div className="text-secondary">
                            <div className="row w-100 justify-center py-2">
                                <button className="btn-link-lg" onClick={stepSignup}>{t('create_account')}</button>
                            </div>
                        </div>
                    </div>
                    {complete == true ? <div className="m-w-300">
                        <div className="my-2 w-100 text-center">
                            <IoMail className="icon_done_large Isexpand"/>
                        </div>
                        <h3 className="mb-1 w-100 text-center Isexpand">{t('check_your_email')}</h3>
                        <p className="w-100 text-center Isexpand">{t('send_instructions')}</p>
                        <div className="my-3 w-100">
                            <button className="btn_login waves-effect button waves-light Isexpand" onClick={aceptForgot} disabled={loading}>
                                {t('accept')}
                            </button>
                        </div>
                    </div> : <>
                        <figure className="cont_img m-w-300">
                            <img className="img_sing_log" src="/images/logo_iso.png" />
                            <h1 className="text-center sm-tt-forgot w-100">{t('forgot_password')}</h1>
                            <div className="limpio n-vert-mg">
                                <p className="text-secondary">{t('enter_you_email')}</p>
                            </div>
                        </figure>
                        <div className="cnt_inp">
                            <IoMail className="icon-inp mr-tp-icn"/>
                            <input className="inp_login w-100" type="text" name="email" value={user.user} onInput={handleChange} placeholder={t('email')} />
                        </div>
                        <div className="cnt_inp t_center">
                            <button className="btn_login waves-effect button waves-light" id="btn_login" onClick={handleForgot} disabled={!validEmail(user.email)}>
                                <div className="row justify-center justify-items-center w-100">
                                    {loading == false ? <span className="fs-7 fw-500">{t('send_access_link')}</span> : ''}
                                    {loading ? <div className="mx-1 spinner_sm"></div> : ''}
                                </div>
                            </button>
                        </div>
                        <div className="cnt_inp">
                            {alert !== null ? (<div className="row my-2">
                                <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                            </div>) : ''}
                        </div>
                    </>}
                </div>
                <div className="p-3"></div>
            </div>
        </div>
    )
}
import {useState, useEffect, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import { StatusBar } from '@capacitor/status-bar';
import {IoLockClosedOutline, IoEye, IoLogoGoogle, IoLogoFacebook, IoOpen} from "react-icons/io5";
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import { useTranslation } from 'react-i18next';

export default function LoginForm({setPortal, setShowIntro, portal}) {
    const {loading, setLoading, urlbase, loadSessionToken} = useApp();
    const { t } = useTranslation();
    const [user, setUser ] = useState({
        user:'',
        password:''
    });
    const inputPass = useRef(null);
    const [alert, setAlert ] = useState(null);
    const navigate = useNavigate();


    const handleLogin = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            button.disabled = true;
            setLoading(true);
            let response = await fetch(urlbase+"/login_user", {
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(user)
            });
            const res = await response.json();
            if (res.token) {
                setShowIntro(true);
                setTimeout(async () => {
                    let load = await loadSessionToken(res);
                    setShowIntro(false);
                    if (res.user.rol == 'Redactor') {
                        navigate("/editor/catalogo/apartadas");
                    }else{
                        navigate("/app");
                    }
                    updateStatusBarColor('#212529');
                }, 1500);
            }else{
                if (res.message == 'Campos vacios') {
                    setAlert(t('empty_fields'));
                }else if (res.message == 'Usuario o contraseña incorrectos'){
                    setAlert(t('incorrect_username_or_password'));
                }else{
                    setAlert(t('login_issue_contact_admin'))
                }
                setUser({user:'', password:''});
            }
            setLoading(false);
            button.disabled = false;
        } catch(e) {
            // statements
            console.log(e);
            setAlert(t('server_error'));
            button.disabled = false;
            setLoading(false);
            setUser({user:'', password:''});
        }
    }
    const handleChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handlePortal = () => {
        setPortal("signup");
    }
    const handleInput = (e) => {
        if(inputPass.current.type == "password"){
            inputPass.current.type = 'text';
            e.currentTarget.classList.add('blue-focus');
        }else{
            e.currentTarget.classList.remove('blue-focus');
            inputPass.current.type = 'password';
        }
    }
    const updateStatusBarColor = (color) => {
        // Cambia la metaetiqueta theme-color
        document.querySelector("meta[name=theme-color]")?.setAttribute("content", color);
        // Cambia el color de la barra de estado en Capacitor
        StatusBar.setBackgroundColor({ color: color });
    };
    const handleForgot = () => {
        setPortal("forgot");
    }

    

	return (
		<div>
            <div className="Isexpand cont_login py-0">
                <div className="contene_log" id="cont_login">
                    <figure className="cont_img cont_img_l">
                        <img className="img_tit_log" src="/images/icon.png" />
                        <div className="limpio n-vert-mg">
                            <p className="text-secondary">{t('slogan_song_or')}</p>
                        </div>
                    </figure>
                    <div className="w-100 py-1"></div>
                    <div className="w-100 py-2 showMovil"></div>
                    <div className="w-100 py-2 showMovil"></div>
                    <form className="form_login" action="/login" method="post">
                        <div className="cnt_inp">
                            <img src="/images/usuario.png" className="img-inp"/>
                            <input className="inp_login w-100" type="text" name="user" value={user.user} onChange={handleChange} placeholder={t('email')} />
                        </div>
                        <div className="cnt_inp">
                            <IoLockClosedOutline className="icon-inp"/>
                            <input className="inp_login w-100" ref={inputPass} type="password" name="password" value={user.password} onChange={handleChange} placeholder={t('password')+"..."}/>
                            <IoEye className="show-pass" onClick={handleInput}></IoEye>
                        </div>
                        <div className="cnt_inp t_right">
                            <div className="row w-100 justify-end">
                                <a href="#" className="btn-link-lg" onClick={handleForgot}>{t('forgot_password')}</a>
                            </div>
                        </div>
                        <div className="cnt_inp t_center">
                            <button className="btn_login waves-effect button waves-light" id="btn_login" onClick={handleLogin}>
                                <div className="row justify-center justify-items-center w-100">
                                    {loading == false ? <span className="fs-7 fw-500">{t('login')}</span> : ''}
                                    {loading ? <div className="mx-1 spinner_sm"></div> : ''}
                                </div>
                            </button>
                        </div>
                        <div className="cnt_inp">
                            {alert !== null ? (<div className="row my-2">
                                <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                            </div>) : ''}
                        </div>
                    </form>
                    <div>
                        <div className="cnt_inp my-2">
                            <div className="row w-100 justify-center py-2">
                                <button className="btn-link-lg" onClick={handlePortal}>{t('create_account')}</button>
                            </div>
                            <div className="w-100 py-1 showMovil"></div>
                            <div className="row w-100 justify-between align-items-center gap-1">
                                <div className="col col-sm-12">
                                    <button className="row w-100 button-social-login">
                                        <div className="row w-100 justify-center align-items-center">
                                            <IoLogoFacebook className="lg-icn-face" />
                                            <a className="is_link col-7 col-sm-4">Facebook</a>
                                        </div>
                                    </button>
                                </div>
                                <div className="col col-sm-12">
                                    <button className="row w-100 button-social-login my-1">
                                        <div className="row w-100 justify-center align-items-center">
                                            <IoLogoGoogle className="lg-icn-goog" />
                                            <a className="is_link col-7 col-sm-4">Google</a>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-3"></div>
            </div>
        </div>
	)
}
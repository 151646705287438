import {useState, useEffect, useRef} from 'react'
import {IoSend, IoSearch, IoCreate, IoCloseSharp, IoPeople, IoPersonAddSharp} from "react-icons/io5";
import AlertDialog from "../../components/ui/AlertDialog";
import Modal from "../../components/ui/Modal";
import SnackBar from "../../components/ui/SnackBar";
import OptionMovil from "../../components/ui/OptionMovil";
import AuthorForm from "../../components/forms/AuthorForm"
import Author from "../../components/views/Author"
import ProgressIntern from "../../components/ui/ProgressIntern";
import {searchTable, extractDateR} from "../../components/utility/utils";
import {useApp} from "../../context/appContext";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


export default function Collaborators(){
    const {setNColl, setLoading, urlbase, myHeaders} = useApp();
    const { t } = useTranslation();
    const [alert, setAlert ] = useState(null);
    const [message, setMessage] = useState(false);
    const [searching, setSearching] = useState(false);
    const [valsearch, setValSearch] = useState("");
    const [study, setStudy] = useState(null);
    const [type, setType] = useState("Recibidas");
    const [isload, setIsLoad] = useState(false);
    const [collaborations, setCollaborations] = useState([]);
    const [senders, setSenders] = useState([]);
    const [receivers, setReceivers] = useState([]);
    const [openv, setOpenv] = useState(false);
    const [idview, setIdView] = useState(null);
    const inputSearch = useRef(null);

    const handleClear = (e) => {
        setTimeout(() => {
            setStudy(null);
            setValSearch('');
        }, 200);
    }
    const abrirModal = () => {
        setSearching(true);
        setTimeout(() => {
            searchStudy();
        }, 1500);
    }
    const searchStudy = async () => {
        if (valsearch != '') {
            try {
                const response = await fetch(urlbase+"/app/get_study_collaboration/"+valsearch, {
                    method: 'GET',
                    headers: myHeaders(),
                })
                const data = await response.json();
                if (data && data.clave == 'exito') {
                    setStudy(data.study);
                    setSearching(false);
                }else {
                    setMessage({text: t('id_invalid'), type:"error"});
                    setStudy(null);
                    setSearching(false);
                    setValSearch('');
                }
            } catch (err){
                console.log(err);
            }
            setSearching(false);
        }else{
            setSearching(false);
        }
    }
    const handleCollaboration = async (e) => {
        let id = e.currentTarget.id;
        if (study && study.id_collaboration) {
            try {
                const response = await fetch(urlbase+"/app/send_request_collaboration", {
                    method: 'POST',
                    headers: myHeaders(),
		            body: JSON.stringify(study)
                })
                const data = await response.json();
                if (data && data.clave == 'exito') {
                    setStudy(null);
                    setValSearch('');
                    setMessage({text: t('request_sent'), type:"done"});
                    setCollaborations([]);
                    setSenders([]);
                    setReceivers([]);
                    loadRequest();
                    setType("Enviadas");
                }else {
                    setMessage({text: data.mensaje, type:"error"});
                    setStudy(null);
                    setValSearch('');
                }
            } catch (err){
                console.log(err);
            }
            setSearching(false);
        }else{
            setSearching(false);
        }
    }
    const handleAcept = async (e) => {
        let id = e.currentTarget.id;
        if (id) {
            try {
                const response = await fetch(urlbase+"/app/acept_request_collaboration", {
                    method: 'POST',
                    headers: myHeaders(),
		            body: JSON.stringify({state:true, id:id})
                })
                const res = await response.json();
                if (res && res.clave == 'exito') {
                    loadRequest();
                    setMessage({text: t('successfully_accepted'), type:"done"});
                }else {
                    setMessage({text: res.mensaje, type:"error"});
                }
            } catch (err){
                console.log(err);
            }
        }
    }
    const handleCancel = async (e) => {
        let id = e.currentTarget.id;
        if (id) {
            let conf = window.confirm(t('are_you_cancel'));
            if (conf) {
                try {
                    const response = await fetch(urlbase+"/app/cancel_request_collaboration", {
                        method: 'POST',
                        headers: myHeaders(),
                        body: JSON.stringify({state:true, id:id})
                    })
                    const res = await response.json();
                    if (res && res.clave == 'exito') {
                        loadRequest();
                        setMessage({text: t('request_canceled'), type:"error"});
                    }else {
                        setMessage({text: res.mensaje, type:"error"});
                    }
                } catch (err){
                    console.log(err);
                }
            }
        }
    }
    const loadRequest = async () => {
        try {
            setLoading(true);
            const response = await fetch(urlbase+"/app/get_collaborations_requests", {
                method: 'GET',
                headers: myHeaders(),
            })
            const data = await response.json();
            if (data.clave == "exito") {
                setCollaborations(data.collaborations);
                setSenders(data.senders);
                setReceivers(data.receivers);
                setNColl(data.receivers.length);
            }
            setIsLoad(true);
            setLoading(false);
        } catch (err){
            console.log(err);
        }
    }
    const handleRecibidas = async (e) => {
        setType('Recibidas');
    }
    const handleEnviadas = async (e) => {
        setType('Enviadas');
    }
    const handleView = async (e) => {
        setIdView(e.currentTarget.id);
        setOpenv(true);
    }
    const handleSearch = async (e) => {
        setValSearch(e.target.value);
    }

    useEffect(() => {
        setCollaborations([]);
        setSenders([]);
        setReceivers([]);
        setMessage(false);
        loadRequest();
        setIsLoad(false);
    }, [])


    if (!isload) return <div className="Isexpand">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressIntern />
            </div>
        </div>
    </div>;

    return(
        <div className="fadeIn max-w-600">
            <div className="limpio">
                <div className={study ? "row justify-between justify-items-center w-100 isBlur" : "row justify-between justify-items-center w-100"}>
                    <div className="py-2 w-100"></div>
                    <div className="row w-100">
                        <h1 className="title-lg">{t('collaborators')}</h1>
                    </div>
                    <div className="row justify-center justify-items-center gap-2 w-100">
                        <div className="col py-2">
                            <input type="number" className="inp_add no-arrow-inp" placeholder="Songor id..." value={valsearch} ref={inputSearch} onInput={handleSearch}/>
                        </div>
                        <div className="col-3 py-2 md-p-2 text-left">
                            <button className="btn btn-sm btn-primary waves-effect waves-light" onClick={abrirModal} disabled={searching}>
                                <div className="row w-100 align-items-center justify-center sm-text-center">
                                    {searching ? <div className="mx-1 spinner_download cl-mg-0"></div> : <IoSearch className="col col-sm-12 icon-btn mr-m cl-mg-0 w-100" />}
                                    <span className="col col-sm-12 sm-txt-85">{t('search')}</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-100">
                    {study ? 
                        <div className="row w-100 tag-search slideInUp mb-3">
                            <div className="row w-100 justify-between align-items-center tag-search p-2">
                                <div className="col col-sm-6">
                                    <div className="row gap-1 justify-items-center">
                                        <div className="col-1">
                                            <img src={urlbase+'/images/caratula.jpg'} className="img-profile-med" />
                                        </div>
                                        <div className="col text-left">
                                            <p className="tag_inl is_link clear-my">
                                                <span>{study.name}</span>
                                            </p>
                                            <p className="text-secondary sm-text clear-my">{study.city} - {study.admin && study.admin.name ? study.admin.name+" "+study.admin.lastname : ''}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="row gap-2">
                                        <div className="col">
                                            {study && study.iam ? '': <button className="btn btn-sm btn-primary waves-effect waves-light w-100" id={study.id_collaboration} onClick={handleCollaboration}>
                                                <div className="row w-100 align-items-center justify-center sm-text-center">
                                                    {searching ? <div className="mx-1 spinner_download cl-mg-0"></div> : <IoPersonAddSharp className="col col-sm-12 icon-btn mr-m cl-mg-0 w-100" />}
                                                    <span className="col col-sm-12 sm-txt-85">Colaboración</span>
                                                </div>
                                            </button>}
                                        </div>
                                        <div className="col-2">
                                            <button className="btn-closed waves-effect" onClick={handleClear}>
                                                <div className="row w-100 justify-center">
                                                    <IoCloseSharp className="icon-btn" />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div> 
                        </div> 
                    : 
                        ''
                    }
                </div>
                <div className={study ? "py-2 w-100 isBlur" : "py-2 w-100"}>
                    <div className="row w-100 justify-between">
                        <ul className="list">
                            <li className="item-tab">
                                <button className={type == "Recibidas" ? 'waves-effect link-tab act-tab' : 'waves-effect link-tab'} onClick={handleRecibidas}>
                                    <IoPeople className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab mx-1'>{t('received')}</span>
                                </button>
                                <button 
                                    className={type == "Enviadas" ? 'waves-effect link-tab act-tab mx-1' : 'waves-effect link-tab mx-1'}
                                    onClick={handleEnviadas}>
                                        <IoSend className="icon-md icn-mov align-icon"/>
                                        <span className='txt_tab mx-1'>{t('sent')}</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="row my-2">
                        <table className="table_outline">
                            <thead>
                                <tr>
                                </tr>
                            </thead>
                            <tbody>
                                {type == 'Recibidas' ? receivers.length > 0 ? receivers.map((receiver, ind) => {
                                    return(<tr key={'t_u_'+ind} >
                                        <td>
                                            <p className="tag_inl is_link clear-my" id={receiver._id} onClick={handleView}>{receiver.sender.name}</p>
                                            <p className="stx_tt text-secondary sm-text py-1">{extractDateR(receiver.created)}</p>
                                        </td>
                                        <td className='text-secondary ocultar-sm'>{t('pending')}</td>
                                        <td className="text-right">
                                            <button className="btn btn-sm btn-primary waves-effect waves-light" id={receiver._id} onClick={handleAcept}>
                                                <div className="row w-100 align-items-center justify-center sm-text-center">
                                                    <IoPeople className="col icon-btn mr-m w-100" />
                                                    <span className="col sm-txt-85">{t('accept')}</span>
                                                </div>
                                            </button>
                                        </td>
                                    </tr>)
                                }): t('no_pending_requests'): senders.length > 0 ? senders.map((sender, ind) => {
                                    return(<tr key={'t_u_'+ind}>
                                        <td>
                                            <p className="tag_inl is_link clear-my" id={sender._id} onClick={handleView}>{sender.receiver.name}</p>
                                            <p className="stx_tt text-secondary sm-text py-1">{extractDateR(sender.created)}</p>
                                        </td>
                                        <td className='text-secondary ocultar-sm'>{t('pending')}</td>
                                        <td className="text-right">
                                            <button className="btn btn-sm btn-danger" id={sender._id} onClick={handleCancel}>
                                                <div className="row w-100 align-items-center">
                                                    <span className="col">{t('cancel_request')}</span>
                                                </div>
                                            </button>
                                        </td>
                                    </tr>)
                                }) : t('no_pending_requests')}
                            </tbody>
                        </table>
                    </div>
                    <div className="row limpio w-100 py-2"></div>
                    <div className="row justify-between justify-items-center sm-py-3">
                        <h2 className="title-lg">{t('my_collaborators')}</h2>
                    </div>
                    <div className="row my-2">
                        <table className="table_outline">
                            <thead>
                                <tr>
                                </tr>
                            </thead>
                            <tbody>
                                {collaborations.length > 0 ? collaborations.map((collaborator, ind) => {
                                    return(<tr key={'t_u_'+ind}>
                                        <td>
                                            <div className="row gap-1 justify-items-center p-1">
                                                <div className="col-1">
                                                    <img src={'/images/logo_black.png'} className="img-profile-sm" />
                                                </div>
                                                <div className="col text-left">
                                                    <p className="tag_inl is_link" id={collaborator._id} onClick={handleView}>{collaborator.name}</p>
                                                    <p className="text-secondary sm-text w-100"> {t('created_t')} {extractDateR(collaborator.created)}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-secondary ocultar-sm text-right'>{t('collaborator')}</td>
                                    </tr>)
                                }) : 'No hay colaboradores'}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="py-2 w-100"></div>
            <div className="py-2 w-100"></div>
            <div className="py-2 w-100"></div>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}
import {useState, useEffect, useRef} from 'react'
import AlertDialog from "../ui/AlertDialog";
import StepItem from "../ui/StepItem";
import {useApp} from "../../context/appContext";
import {validPhone, validEmail, validPassword} from "../../components/utility/utils";
import { useTranslation } from 'react-i18next';
import {IoChevronBack, IoLockClosedOutline, IoEye, IoCheckmarkSharp} from "react-icons/io5";

export default function SignupForm({setPortal}) {
    const {urlbase, myHeaders} = useApp();
    const { t } = useTranslation();
    const [alert, setAlert ] = useState(null);
    const [loading, setLoading ] = useState(false);
    const [step, setStep] = useState(1);
    const inputPass = useRef(null);
    const [user, setUser] = useState({
        name: "",
        lastname: "",
        email: "",
        phone: "",
        gender: "",
        password: ""
    });

    const handlePortal = () => {
        if (user.name != '' && user.lastname != '') {
            let conf = window.confirm(t('want_to_leave_registation'));
            if (conf) {
                setPortal("login");
            }
        }else{
            setPortal("login");
        }
    }
    const handleChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleInput = (e) => {
        if(inputPass.current.type == "password"){
            inputPass.current.type = 'text';
            e.currentTarget.classList.add('blue-focus');
        }else{
            e.currentTarget.classList.remove('blue-focus');
            inputPass.current.type = 'password';
        }
    }
    const handleBack = (e) => {
        if (step >= 2) {
            setStep(step => step - 1);
        }else{
            setStep(1);
        }
    }
    const stepName = (e) => {
        if (user.name != '' && user.lastname != '') {
            setLoading(true);
            setTimeout(() => {
                setStep(2);
                setLoading(false);
            }, 600);
        }else{
            setAlert(t('complete_name_surname'));
        }
    }
    const stepEmail = (e) => {
        if (validEmail(user.email) && user.phone != '') {
            setLoading(true);
            setTimeout(() => {
                setStep(3);
                setLoading(false);
            }, 600);
        }else{
            setAlert(t('complete_phone_email'));
        }
    }
    const stepPassword = async (e) => {
        if (user.password != '') {
            if (validPassword(user.password)) {
                let button = e.currentTarget;
                try {
                    e.preventDefault();
                    button.disabled = true;
                    setLoading(true);
                    let response = await fetch(urlbase+"/register_portal_user", {
                        method: 'POST',
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify({user:user})
                    });
                    const res = await response.json();
                    if (res.clave == "exito") {
                        setStep(4);
                    }else{
                        if (res.message == 'Datos no validos') {
                            setAlert(t('invalid_data'));
                        }else if (res.message == 'No pudimos registrar el usuario'){
                            setAlert(t('unabled_to_register'));
                        }else if (res.message == 'Cuenta ya registrada'){
                            setAlert(t('account_repit'));
                        }else{
                            setAlert(t('login_issue_contact_admin'))
                        }
                    }
                    setLoading(false);
                    button.disabled = false;
                } catch(e) {
                    // statements
                    console.log(e);
                    setAlert(t('server_error'));
                    button.disabled = false;
                    setLoading(false);
                }
            }else{
                setAlert(t('min_password_character'));
            }
        }else{
            setAlert(t('an_valid_paswword'));
        }
    }
    const stepLogin = async (e) => {
        setPortal("login");
    }

    return (
        <div>
            <div className="Isexpand cont_signup py-0">
                <div className="contene_log" id="cont_login">
                    <div className="row justify-between align-items-center cnt_inp">
                        <button className="btn-link-lg" onClick={handlePortal}>
                            <div className="row align-items-center">
                                <IoChevronBack className="lg-icn-face" />
                                {t('login')}
                            </div>
                        </button>
                        <div className="text-secondary">
                            <StepItem check={step >= 1 ? true : false}/>
                            <StepItem check={step >= 2 ? true : false}/>
                            <StepItem check={step >= 3 ? true : false}/> 
                            <StepItem check={step >= 4 ? true : false}/>
                        </div>
                    </div>
                    <figure className="cont_img">
                        <img className="img_sing_log" src="/images/logo_iso.png" />
                        <h1 className="text-center w-100">{t('create_account')}</h1>
                        <div className="limpio n-vert-mg">
                            <p className="text-secondary">{t('slogan_song_or')}</p>
                        </div>
                    </figure>
                    <div className="cnt_inp">
                        {step == 1 ? <div className="Isexpand">
                            <div className="row">
                                <label className="lb-inp-usm mb-1">{t('name')}</label>
                                <input type="text" className="inp_add" value={user.name} onChange={handleChange} name="name" placeholder={t('name')+" *"} autoComplete="off"/>
                            </div>
                            <div className="row my-1">
                                <label className="lb-inp-usm mb-1">{t('lastname')}</label>
                                <input type="text" className="inp_add" value={user.lastname} onChange={handleChange} name="lastname" placeholder={t('lastname')+" *"} autoComplete="off"/>
                            </div>
                            <div className="row">
                                <label className="lb-inp-usm mb-1">{t('gender')}</label>
                                <div className='position-relative w-100'>
                                    <select className="inp_add clear_sl_p" value={user.gender} name="gender" onChange={handleChange}>
                                        <option value="">{t('gender')}</option>
                                        <option value="H">{t('male')}</option>
                                        <option value="M">{t('female')}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-100 py-2"></div>
                            <div className="row justify-end align-items-center">
                                <div className="col">
                                    <button className="btn-link-lg" onClick={handleBack}>
                                        <div className="row align-items-center">
                                            <IoChevronBack className="lg-icn-face" />
                                            {t('btn_back')}
                                        </div>
                                    </button>
                                </div>
                                <div className="col-7">
                                    <button className="btn_login waves-effect button waves-light" onClick={stepName} disabled={loading}>
                                        {loading == false ? <span className="fs-7 fw-500">{t('next_button')} </span> : ''}
                                        {loading ? <div className="mx-1 spinner_sm"></div> : ''}
                                    </button>
                                </div>
                            </div>
                        </div> : step == 2 ? <div className="Isexpand">
                            <div className="row Isexpand">
                                <label className="lb-inp-usm mb-1">{t('phone')}</label>
                                <input type="text" className="inp_add" value={user.phone} onInput={validPhone} onChange={handleChange} name="phone" placeholder={t('phone')} autoComplete="off"/>
                            </div>
                            <div className="row Isexpand my-1">
                                <label className="lb-inp-usm mb-1">{t('email')}</label>
                                <input type="text" className="inp_add text-lowercase" value={user.email} onChange={handleChange} name="email" placeholder={t('email')} autoComplete="off"/>
                            </div>
                            <div className="w-100 py-2"></div>
                            <div className="row Isexpand justify-end align-items-center">
                                <div className="col">
                                    <button className="btn-link-lg" onClick={handleBack}>
                                        <div className="row align-items-center">
                                            <IoChevronBack className="lg-icn-face" />
                                            {t('btn_back')}
                                        </div>
                                    </button>
                                </div>
                                <div className="col-7">
                                    <button className="btn_login waves-effect button waves-light" onClick={stepEmail} disabled={loading}>
                                        {loading == false ? <span className="fs-7 fw-500">{t('next_button')} </span> : ''}
                                        {loading ? <div className="mx-1 spinner_sm"></div> : ''}
                                    </button>
                                </div>
                            </div>
                        </div> : step == 3 ? <>
                            <p className="mb-2">{t('finally_account')}</p>
                            <div className="row Isexpand py-2">
                                <label className="lb-inp-usm mb-1">{t('password')}</label>
                                <div className="position-relative w-100">
                                    <IoLockClosedOutline className="icon-inp"/>
                                    <input type="password" ref={inputPass} className="inp_login w-100" value={user.password} onChange={handleChange} name="password" placeholder={t('password')+" *"} autoComplete="off"/>
                                    <IoEye className="show-pass" onClick={handleInput}></IoEye>
                                </div>
                                <label className="lb-inp-usm my-2 text-secondary">{t('min_password_character')}</label>
                            </div>
                            <div className="w-100 py-2"></div>
                            <div className="row Isexpand justify-end align-items-center">
                                <div className="col">
                                    <button className="btn-link-lg" onClick={handleBack}>
                                        <div className="row align-items-center">
                                            <IoChevronBack className="lg-icn-face" />
                                            {t('btn_back')}
                                        </div>
                                    </button>
                                </div>
                                <div className="col-7">
                                    <button className="btn_login waves-effect button waves-light" onClick={stepPassword} disabled={loading}>
                                        {loading == false ? <span className="fs-7 fw-500">{t('singnup')}</span> : ''}
                                        {loading ? <div className="mx-1 spinner_sm"></div> : ''}
                                    </button>
                                </div>
                            </div>
                        </> : step == 4 ? <>
                            <div className="my-2 w-100 text-center">
                                <IoCheckmarkSharp className="icon_done_large Isexpand"/>
                            </div>
                            <h3 className="mb-1 w-100 text-center Isexpand">{t('register_successful')}</h3>
                            <p className="w-100 text-center Isexpand">{t('welcome')} <strong>{user.name} {user.lastname}</strong>. {t('now_login')}</p>
                            <div className="my-3 w-100">
                                <button className="btn_login waves-effect button waves-light Isexpand" onClick={stepLogin} disabled={loading}>
                                    {t('i_am_ready')}
                                </button>
                            </div>
                        </> : ''}
                        {alert !== null ? (<div className="row my-2">
                            <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                        </div>) : ''}
                    </div>
                </div>
                <div className="p-3"></div>
            </div>
        </div>
    )
}
import { useRef, useEffect, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import {IoPlaySkipBack, IoPlaySkipForward, IoReload} from "react-icons/io5";
import { MdOutlinePlayCircleFilled, MdPauseCircle } from "react-icons/md";
import { getSongFromIndexedDB } from "../../db";
import AutorsField from "../../components/ui/AutorsField";
import { useApp } from "../../context/appContext";
import {limitText} from "../utility/utils"


const DynamicAudioPlayer = ({ playlist, ...props }) => {
    const {play, setPlay, position, setPosition, urlbase} = useApp();
    const playerRef = useRef(null);
    const [currentSrc, setCurrentSrc] = useState('');

    
    const handleSkip = (seconds) => {
        if (playerRef.current?.audio?.current) {
            const audio = playerRef.current.audio.current;
            let newTime = audio.currentTime + seconds;
            newTime = Math.max(0, Math.min(newTime, audio.duration)); // Evitamos valores fuera del rango
            audio.currentTime = newTime;
        }
    };
    const handleBackSong = (e) => {
        if (playlist.length > 0) {
            setPlay(true);
            if (position == 1) {
                setPosition(playlist.length);
            }else{
                setPosition(prevPos => prevPos - 1);
            }
        }
    }
    const handlenextSong = (e) => {
        if (playlist.length > 0) {
            setPlay(true);
            if (position == playlist.length) {
                setPosition(1);
            }else{
                setPosition(prevPos => prevPos + 1);
            }
        }
    }
    const getSrcFinal = async (song, urlbase) => {
        let armId = song._id+song.state;
        if (song.state == 'Apartada') {
            armId = song._id+'Libre';
        }
        const songBlob = await getSongFromIndexedDB(song._id+song.state);
        if (song.state === 'Grabada') {
            return songBlob ? URL.createObjectURL(songBlob) : `${urlbase}${song.master_recording}`;
        } else if (song.state === 'Libre' || song.state === 'Apartada') {
            return songBlob ? URL.createObjectURL(songBlob) : `${urlbase}${song.demo_track}`;
        } else {
            return songBlob ? URL.createObjectURL(songBlob) : `${urlbase}${song.track}`;
        }
    };
  
    useEffect(() => {
        const updateSrc = async () => {
            if (playlist.length > 0 && playlist[position - 1]) {
                const song = playlist[position - 1];
                const src = await getSrcFinal(playlist[position - 1], urlbase);
                setCurrentSrc(src);
                if ('mediaSession' in navigator) {
                    const autorNames = song && song.autors ? song.autors.map(autor => autor.name).join(', ') : '';
                    navigator.mediaSession.metadata = new window.MediaMetadata({
                      title: song.title,
                      artist: song.autors ? song.autors.map(a => a.name).join(', ') : 'Songor',
                      album: 'Songor',
                      artwork: [
                        { src: song.cover_file != null ? urlbase+song.cover_file : urlbase+'/images/caratula.jpg', sizes: '512x512', type: 'image/png' }
                      ]
                    });
                    // Botón de "Anterior"
                    navigator.mediaSession.setActionHandler('previoustrack', () => {
                        console.log('Reproducir canción anterior');
                        handleBackSong(); // Debes definir esta función en tu lógica de reproducción
                    });
                    // Botón de "Siguiente"
                    navigator.mediaSession.setActionHandler('nexttrack', () => {
                        console.log('Reproducir siguiente canción');
                        handlenextSong(); // Debes definir esta función en tu lógica de reproducción
                    });
                }
            }
        };
        updateSrc();
    }, [playlist, position, urlbase]);
  
    return (
      <>
        {playlist.length > 0 && playlist[position - 1] && (
            <div className={`w-100 custom-player-2`}>
            <AudioPlayer
                ref={playerRef}
                src={currentSrc}
                {...props} // Pasamos las propiedades adicionales como `onPlay`, `onPause`, etc.
                customIcons={{
                    play: <MdOutlinePlayCircleFilled />,
                    pause: <MdPauseCircle />,
                }}
                customControlsSection={[
                    <div className="text-right">
                        <button className='btn_track waves-effect text-center' onClick={handleBackSong}>
                            <IoPlaySkipBack className="icon-md" />
                        </button>
                    </div>,
                    <button onClick={() => handleSkip(-10)} className="custom-button waves-effect waves-light">
                        <div className="position-relative ln-41">
                            <IoReload className="ret-back-10" />
                            <span className="flt-ret-l">10</span>
                        </div>
                    </button>,
                    "MAIN_CONTROLS",
                    <button onClick={() => handleSkip(10)} className="custom-button waves-effect waves-light" >
                        <div className="position-relative ln-41">
                            <IoReload className="ret-next-10"/>
                            <span className="flt-ret-r">10</span>
                        </div>
                    </button>,
                    <div className="text-left">
                        <button className='btn_track waves-effect text-center'  onClick={handlenextSong}>
                            <IoPlaySkipForward className="icon-md" />
                        </button>
                    </div>,
                    <div className="text-center">
                        <div className="img_inl ocultar-sm">
                            <img src={'/images/caratula.jpg'} className="img-profile-sm" />
                        </div>
                        <div className="text_inl text-left">
                            <p className="tag_inl is_link clear-my">{playlist.length > 0 && playlist[position-1] ? limitText(playlist[position-1].title, 18) :  '-'}</p>
                            <p className="text-secondary sm-text clear-my">{playlist.length > 0 && playlist[position-1] ?  <AutorsField autors={playlist[position-1].autors ? playlist[position-1].autors : []} /> :  '-'}</p>
                        </div>
                    </div>
                ]}
                customProgressBarSection={["CURRENT_TIME", "PROGRESS_BAR", "DURATION"]}
                layout="horizontal-reverse" // Diseño apilado
            />
            </div>
        )}
      </>
    );
  };
  
  export default DynamicAudioPlayer;
import {Link} from 'react-router-dom'
import {IoMusicalNoteOutline, IoPersonAddSharp, IoReceipt, IoChevronForwardOutline, IoPersonCircleSharp, IoLayers, IoSettings, IoPeopleCircleSharp } from "react-icons/io5";
import {useApp} from "../../context/appContext";
import { useTranslation } from 'react-i18next';

export default function MoreOptions(){
    const {setLoading, urlbase, myHeaders} = useApp();
    const { t } = useTranslation();


    return(
        <div className="Isexpand">
            <div className="limpio w-100">
                <div className="sm-my-3 w-100"></div>
                <div className="row w-100 mb-3 justify-between">
                    <div className="wrapper-1 w-100 px-2">
                        <Link className={'waves-effect link-tab'} to="/app/enviadas">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoMusicalNoteOutline className="icon-md icn-mov align-icon text-left"/> 
                                    <span className='txt_tab'>{t('sent')}</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/artistas">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoPersonCircleSharp className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>{t('artists')}</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/autores">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <div className='row justify-start'>
                                        <IoPersonAddSharp className="icon-md icn-mov align-icon"/> 
                                    </div>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                            <div className='w-100 txt_tab text-left'>{t('authors_and')}</div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/listas_de_registros">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoLayers className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>{t('records')}</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/usuarios">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoPeopleCircleSharp className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>{t('users')}</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/editoras">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoReceipt className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>{t('editors')}</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                        <Link className={'waves-effect link-tab'} to="/app/configuraciones">
                            <div className='row justify-items-center justify-between'>
                                <div className='col-2 text-left'>
                                    <IoSettings className="icon-md icn-mov align-icon"/> 
                                    <span className='txt_tab'>{t('settings')}</span>
                                </div>
                                <div className='col-1 text-right'>
                                    <IoChevronForwardOutline className="icon-md align-icon"/>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
        </div>
    )
}
import { IoClose, IoAlertCircleSharp } from "react-icons/io5";
import {useState} from 'react' 

export default function InputWarning({mensaje, setMensaje}) {
    const [animation, setAnimation] = useState('fadeInDown');

    const handleClosed = () => {
        setAnimation('fadeOut');
        setTimeout(function() {
            setMensaje(null);
        }, 400);
    }
    return (
        <div className={"row w-100 justify-center justify-items-center rounded-4"}>
            <p className="text-message-srt ">{mensaje ? mensaje : ''}</p>
        </div>
    )
}
import {useState, useEffect, useRef} from 'react'
import {IoAddSharp, IoEllipsisVerticalSharp, IoCreate, IoRemoveCircle, IoMusicalNotes, IoChevronBack} from "react-icons/io5";
import AlertDialog from "../../components/ui/AlertDialog";
import Modal from "../../components/ui/Modal";
import SnackBar from "../../components/ui/SnackBar";
import OptionMovil from "../../components/ui/OptionMovil";
import ArtistForm from "../../components/forms/ArtistForm"
import ProgressIntern from "../../components/ui/ProgressIntern";
import {limitText, searchTable} from "../../components/utility/utils";
import {useApp} from "../../context/appContext";
import { updateLocalArtistas, getArtistas } from "../../db";
import { useTranslation } from 'react-i18next';



export default function Artistas({type}){
    const {online, setLoading, urlbase, myHeaders} = useApp();
    const { t } = useTranslation();
    const [alert, setAlert ] = useState(null);
    const [message, setMessage] = useState(false);
    const [isload, setIsLoad] = useState(false);
    const [artists, setArtists] = useState([]);
    const [open, setOpen] = useState(false);
    const [option, setOption ] = useState(false);
    const [opend, setOpend] = useState(false);
    const [idref, setIdRef] = useState(null);
    const [idartist, setIdArtist] = useState(null);
    const tableSer = useRef(null);

    const handleBack = (e) => {
        setTimeout(() => {
            setOpen(false);
            setAlert(null);
        }, 200);
    }
    const abrirModal = () => {
        setOpen(true);
    }
    const handleClosed = (e) => {
        setOpen(false);
        setOpend(false);
        setIdRef(null);
        setIdArtist(null);
        e.stopPropagation();
    }
    const loadArtists = async () => {
        if (online) {
            try {
                setIdRef(null);
                setIdArtist(null);
                setLoading(true);
                let prms = '';
                if (type) {
                    prms = "/Distribuidor";
                }
                const response = await fetch(urlbase+"/app/get_artists"+prms, {
                    method: 'GET',
                    headers: myHeaders(),
                })
                const data = await response.json();
                setArtists(data);
                setIsLoad(true);
                setLoading(false);
                await updateLocalArtistas(data);
            } catch (err){
                console.log(err);
                console.warn('Fallo al obtener datos online, usando datos offline');
                const data = await getArtistas();
                setArtists(data);
                setIsLoad(true);
                setLoading(false);
            }
        } else {
            console.warn('Sin conexión, usando datos offline');
            const data = await getArtistas();
            setArtists(data);
            setIsLoad(true);
            setLoading(false);
        }
    }
    const delArtist = async (e, id) => {
        try {
            e.target.disabled = true;
            setLoading(true);
            const response = await fetch(urlbase+"/app/delete_artist/"+idref, {
                method: 'DELETE',
                headers: myHeaders()
            })
            const data = await response.json();
            if (data.clave == "exito") {
                setArtists(artists.filter((artist) => artist._id !== idref));
                setMessage({text: "Artista eliminado", type:"error"});
                setOpend(false);
                setIdRef(null);
            }else{
                setAlert(data.mensaje);
            }
            setLoading(false);
            e.target.disabled = false;
        } catch (err) {
            console.log(err);
            setLoading(false);
            e.target.disabled = false;
        }
    }
    const handleDelete = async (e) => {
        setIdRef(e.currentTarget.id);
        setOpend(true);
    }
    const handleEdit = async (e) => {
        setIdArtist(e.currentTarget.id);
        setOpen(true);
    }
    const handleOptions = async (e) => {
        setIdRef(e.currentTarget.id);
        setIdArtist(e.currentTarget.id);
        setOption(true);
    }
    const handleSearch = async (e) => {
        var inp = e.target;
        searchTable(tableSer.current, inp.value);
    }
    const handleSelect = async (e) => {
        e.target.select();
    }

    useEffect(() => {
        setArtists([]);
        setMessage(false);
        loadArtists();
        setIsLoad(false);
    }, [type])


    if (!isload) return <div className="Isexpand">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressIntern />
            </div>
        </div>
    </div>;

    return(
        <div className="fadeIn">
            <div className="px-3">
                <div className="row justify-between justify-items-center sm-py-3">
                    <div className="col-2 ocultar-sm">
                        <h1 className="title-lg my-1">{t('artists')}</h1>
                    </div>
                    <div className="col-4 col-sm-9 py-2">
                        <input type="search" className="inp_add" placeholder={t('search')} onInput={handleSearch} onClick={handleSelect}/>
                    </div>
                    <div className="col-3 py-2 md-p-2 text-right">
                        <button className="btn-icon btn-primary waves-effect waves-light btn-flt-mov" onClick={abrirModal}>
                            <div className="row w-100 align-items-center">
                                <IoAddSharp className="col col-sm-12 icon-btn plus_btn mr-m ms-sm-rauto w-100" />
                                <span className="col col-sm-12 sm-txt-85">{t('artist')}</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="row my-2">
                    <table className="table_outline">
                        <thead>
                            <tr>
                                <th>{t('name')}</th>
                                <th className="ocultar-sm">{t('recorded')}</th>
                                <th className="text-center"></th>
                            </tr>
                        </thead>
                        <tbody ref={tableSer}>
                            {artists.map((cliente, ind) => {
                                return(<tr key={'t_u_'+ind}>
                                    <td>
                                        <div className="row gap-1 justify-items-center">
                                            <div className="col-1">
                                                <img src={cliente.photo != null && cliente.photo != 'null' ? urlbase+cliente.photo : urlbase+'/images/usuario.png'} className="img-profile-med" />
                                            </div>
                                            <div className="col text-left">
                                                <p className="tag_inl is_link clear-my">{limitText(cliente.name, 18)}</p>
                                                <p className="text-secondary sm-text showMovil clear-my"><IoMusicalNotes /> {cliente.grabadas} {t('recorded')}  </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="ocultar-sm">
                                        <IoMusicalNotes className="icon-phone-tg"/>
                                        <span className="mx-1 phone-number">{cliente.grabadas ? cliente.grabadas : 0}</span>
                                    </td>
                                    <td className="text-center">
                                        <button className="btn btn-sm btn-outline-primary mx-1 ocultar-sm" id={cliente._id} onClick={handleEdit}>{t('edit')}</button>
                                        <button className="btn btn-sm btn-outline-danger ocultar-sm" id={cliente._id} onClick={handleDelete}>{t('delete')}</button>
                                        <button className="btn btn-sm btn-outline-transparent waves-effect show-sm" id={cliente._id} onClick={handleOptions}>
                                            <IoEllipsisVerticalSharp className="icon-opc"/>
                                        </button>
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <Modal open={open} setOpen={setOpen} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <ArtistForm open={open} setOpen={setOpen} isComplete={loadArtists} artistId={idartist} type={'Artista'} setMessage={setMessage} handleClosed={handleClosed}/>
            </Modal>
            <Modal open={opend} setOpen={setOpend} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <div className="head_modal justify-items-center">
                    <button className='btn_back waves-effect text-center' onClick={handleBack}>
                        <IoChevronBack className='icon-md' />
                    </button>
                    <h5 className="modal-tittle">{t('delete_this_artist')}</h5>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className="modal_body text-center sm-text-left">
                    <p className="color-red">{t('this_artist_permanently_removed')}</p>
                </div>
                {alert !== null ? (<div className="row my-2">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
                <div className="modal_footer">
                    <div className="d-flex justify-end">
                        <div>
                            <button className="btn btn-danger waves-effect waves-light" onClick={delArtist}>{t('delete')}</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <OptionMovil open={option} setOpen={setOption} setIdClient={setIdArtist}>
                <ul className="list">
                    <div className="item-list waves-effect" id={idartist} onClick={handleEdit}>
                        <div className="cont-icon">
                            <IoCreate className="icon_item"/>
                        </div>
                        <span className="tex_item">{t('edit')}</span>
                    </div>
                    <div className="item-list waves-effect" id={idref} onClick={handleDelete}>
                        <div className="cont-icon">
                            <IoRemoveCircle className="icon_item"/>
                        </div>
                        <span className="tex_item">{t('delete')}</span>
                    </div>
                </ul>
            </OptionMovil>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}
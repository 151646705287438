import { IoWifi } from "react-icons/io5";
import { useTranslation } from 'react-i18next';


export default function NotConection({handleClosed}) {
    const { t } = useTranslation();
    return(
        <div className='cont-not-conection w-100'>
            <div className="row w-100 text-center justify-center">
                <IoWifi className="icn_wifi_not"/>
                <div className="mb-2">
                    <h4 className="tt_avis_not">{t('no_internet_connection')}</h4>
                    <p className="text_ul_sm">{t('actions_require_internet')}</p>
                </div>
                <button className="btn btn-primary waves-effect waves-light" onClick={handleClosed}>Ok</button>
            </div>
        </div>
    )
}
import {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";
import { IoCopyOutline  } from "react-icons/io5";
import SnackBar from './SnackBar';

export default function SideMenu({open, items, children, animation, id_coll}){
    let [elements, setElements] = useState([]);
    const [message, setMessage] = useState(false);

    const handleCopy = (event) => {
        const id = event.target.id; // Obtener el id del elemento clickeado
        window.navigator.clipboard.writeText(id)
        .then(() => setMessage({text: "copiado", type:"done"}))
        .catch((err) => console.error("Error al copiar: ", err));
    };

    useEffect(() => {
        setElements(items);
    }, []);

    return(
        <div className={"fixed-right-menu bg-light col-2 "+animation} id="menu_slide">
            <div className="position-sticky pt-3">
                {id_coll ? 
                    <div className="contain_id_side">
                        <div><strong className="sm-text text-uppercase text-secondary">ID SONGOR</strong></div>
                        <div className="row align-items-center">
                            <span className="sm-text">{id_coll}</span><IoCopyOutline className='mx-1 icon-cp-s' id={id_coll} onClick={handleCopy}/>
                        </div> 
                    </div> 
                : ''}
                {children}
                <ul className="nav flex-column md-fs-11">
                    {items.map((item, ind) => {
                        return (
                            <NavLink key={'men_'+ind} to={item.url} className={({isActive}) => (isActive ? 'waves-effect nav-link isActive' : 'waves-effect nav-link')}>
                                {item.icon}
                                <span className="tex_hed_i">{item.name}</span>
                            </NavLink>
                        )
                    })}
                </ul>
            </div>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}
import {useState, useEffect, useRef} from 'react'
import SnackBar from "../../components/ui/SnackBar";
import { useTranslation } from 'react-i18next';

export default function Config() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [message, setMessage] = useState(false);

    const handleIdioma = (e) => {
        let lng = e.currentTarget.value;
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
    }

    useEffect(() => {
        setMessage(false);
    }, []);

    return(
        <div className="fadeIn">
            <div className="px-3">
                <div className="row justify-between justify-items-center sm-py-3">
                    <div className="col-2">
                        <h1 className="title-lg my-1">{t('settings')}</h1>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="row gap-1 w-100">
                        <div className="col-2 col-sm-12">
                            <label className="lb-inp-usm mb-1 w-100">{t('language')}</label>
                            <select className="inp_add clear_sl_p" name="language" onChange={handleIdioma}>
                                <option value="es" selected={currentLanguage == 'es' ? true : false}>Español</option>
                                <option value="en" selected={currentLanguage == 'en' ? true : false}>Ingles</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}
import {useState, useEffect} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {IoChevronBack, IoCaretDownOutline} from "react-icons/io5";
import ProgressModal from "../ui/ProgressModal";
import NotConection from "../ui/NotConection";
import { useTranslation } from 'react-i18next';


export default function UserForm({open, setOpen, isComplete, userId, isEmployee, refName, idSucursal, setMessage, handleClosed}) {
    const {online, setLoading, urlbase, myHeaders} = useApp();
    const { t } = useTranslation();
	const [alert, setAlert ] = useState(null);
    const [isload, setIsLoad] = useState(true);
	const [user, setUser] = useState({
        name: "",
        lastname: "",
        email: "",
        phone: "",
        gender: "",
        sucursal:"",
        rol: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            setOpen(false);
            setAlert(null);
            window.scrollTo(0,0);
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (user.name != "" && user.phone != "" && user.gender != "" && user.rol != "" ) {
                setLoading(true);
                button.disabled = true;
            	var response = null;
	            if (userId) {
	            	response = await fetch(urlbase+"/app/update_user/"+userId, {
		                method: 'PUT',
		                headers: myHeaders(),
		                body: JSON.stringify(user)
		            })
	            }else{
	            	response = await fetch(urlbase+"/app/save_user", {
		                method: 'POST',
		                headers: myHeaders(),
		                body: JSON.stringify(user)
		            })
	            }
	            const res = await response.json();
	            if(res.clave && res.clave == "exito"){
                    if (userId) {
                        setMessage({text: t('user_updated'), type:"done"});
                    }else{
                        setMessage({text: t('user_saved'), type:"done"});
                    }
	                setOpen(false);
	                isComplete();
	                setUser({name: "", lastname: "", email: "", phone: "", gender: "", rol: ""});
                    window.scrollTo(0,0);
                    setIsLoad(true);
	            }else{
                    setAlert(res.mensaje);
                }
                setLoading(false);
                button.disabled = false;
            }else{
            	setAlert(t('complete_the_fields_name_user'));
            }
        } catch (err) {
            console.log(err);
            setAlert(t('server_error'));
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadUser = async (id) => {
    	const res = await fetch(urlbase+"/app/get_user/"+id, {
            method: 'GET',
            headers:myHeaders()
        })
    	const response = await res.json();
    	const data = response.usuario;
        setIsLoad(false);
    	setUser({name:data.name, lastname:data.lastname, sucursal:'', email:data.email, phone:data.phone, gender:data.gender, rol:data.rol});
    }
    const handleChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
        setAlert(null);
    }

    useEffect(() => {
        setUser({name: "", lastname: "", sucursal:"", email: "", phone: "", gender: "", rol: ""});
        setAlert(null);
    	if (userId != null){
    		loadUser(userId);
    	}else{
            setIsLoad(false);
        }
    }, [userId]);

    useEffect(() => {
        setAlert(null);
        if (isEmployee){
            setUser({name: "", lastname: "", sucursal:idSucursal, email: "", phone: "", gender: "", rol: "Vendedor"});
        }
    }, [open]);


    if (!online) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <NotConection handleClosed={handleBack}/>
            </div>
        </div>
    </div>;


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;

	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className="modal-tittle text-uppercase">{userId != null ? t('edit')+' '+t('user') : t('new')+' '+t('user')}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                <div className="row">
                    <div className="col">
                        <label className="lb-inp-usm mb-1">{t('name')}</label>
                        <input type="text" className="inp_add text-uppercase" value={user.name} onChange={handleChange} name="name" placeholder={t('name')+" *"} autoComplete="off"/>
                    </div>
                </div>
                <div className="row  my-2">
                    <div className="col">
                        <label className="lb-inp-usm mb-1">{t('lastname')}</label>
                        <input type="text" className="inp_add text-uppercase" value={user.lastname} onChange={handleChange} name={t('name')} placeholder={t('lastname')+" *"} autoComplete="off"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label className="lb-inp-usm mb-1">{t('phone')}</label>
                        <input type="text" className="inp_add" value={user.phone} onChange={handleChange} name="phone" placeholder={t('phone')+" *"} autoComplete="off"/>
                    </div>
                </div>
                <div className="row  my-2">
                    <div className="col">
                        <label className="lb-inp-usm mb-1">{t('email')}</label>
                        <input type="text" className="inp_add" value={user.email} onChange={handleChange} name="email" placeholder={t('email')} autoComplete="off"/>
                    </div>
                </div>
                <div className="row">
                    <label className="lb-inp-usm mb-1">{t('gender')}</label>
                    <div className='position-relative w-100'>
                        <select className="inp_add clear_sl_p" value={user.gender} name="gender" onChange={handleChange}>
                            <option value="">{t('gender')}</option>
                            <option value="H">{t('male')}</option>
                            <option value="M">{t('female')}</option>
                        </select>
                    </div>
                </div>
                <div className="row my-2">
                    <label className="lb-inp-usm mb-1">Rol</label>
                    <div className='position-relative w-100'>
                        <select className="inp_add clear_sl_p" value={user.rol} name="rol" onChange={handleChange}>
                            <option value="">Rol</option>
                            <option value="Administrador">{t('administrator')}</option>
                            <option value="Redactor">{t('redactor')}</option>
                        </select>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-2">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end">
                    <div>
                        <button className="btn btn-primary waves-effect waves-light text-uppercase" onClick={handleSubmit}>{userId ? t('apply') : t('create')}</button>
                    </div>
                </div>
            </div>
        </div>
	)
}
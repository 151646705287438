import { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { StatusBar } from '@capacitor/status-bar';

export default function Modal({open, setOpen, size, height, children, handleClosed}){
    const [present, setPresent ] = useState("slideInUp");

    const stopEvent = (e) => {
        e.stopPropagation();
    }
    const waitClosed = (e) => {
        e.stopPropagation();
        setPresent("slideOutDown-edit");
        setTimeout(() => {
            setPresent("slideInUp");
            handleClosed();
        }, 400);
    }
    const updateStatusBarColor = (color) => {
        // Cambia la metaetiqueta theme-color
        document.querySelector("meta[name=theme-color]")?.setAttribute("content", color);
        // Cambia el color de la barra de estado en Capacitor
        StatusBar.setBackgroundColor({ color: color });
    };

    useEffect(() => {
        if (open) {
            updateStatusBarColor('#ffffff');
        }else{
            updateStatusBarColor('#212529');
        }
    }, [open])
    
    return( <>
        {open ? <div className={open ? 'cont_modal' : 'cont_modal dnone'} id="modal_opaci" onClick={waitClosed}>
            <div className={'main_modal '+ present +" "+ size + " "+ height } id="main_modal" onClick={stopEvent}>
                <IoClose className="cerrar_modal" onClick={waitClosed}/>
                {children}
            </div>
        </div> : ''}
    </>
    )
}
import {useState, useEffect} from 'react'
import AlertDialog from "../ui/AlertDialog";
import {useApp} from "../../context/appContext";
import {useNavigate} from 'react-router-dom'
import {IoChevronBack, IoSettingsSharp, IoToday, IoCloudUploadOutline, IoCloseCircle, IoCheckmark, IoRefreshCircle, IoArrowDownCircleSharp, IoArrowDownCircleOutline } from "react-icons/io5";
import {extractDate, limitText} from "../utility/utils"
import { deleteSong, saveToIndexedDB } from "../../db";
import ProgressModal from "../ui/ProgressModal";
import NotConection from "../ui/NotConection";
import Select from 'react-select'
import { useTranslation } from 'react-i18next';


export default function ApartarForm({open, setOpen, isComplete, cancionId, isAcc, handleClosed, setMessage, idSucursal, idClient}) {
    const {online, session, loading, setLoading, urlbase, myHeaders} = useApp();
    const { t } = useTranslation();
	const [alert, setAlert ] = useState(null);
    const [takeDown, setTakeDown ] = useState(false);
    const [isload, setIsLoad] = useState(true);
    const [progress, setProgress] = useState(0);
    const [authors, setAuthors] = useState([]);
    const [image, setImage] = useState("/images/caratula.jpg");
    const [clients, setClients] = useState([]);
    const [collaborators, setCollaborators] = useState([]);
    const [selectes, setSelectes] = useState([]);
    const [selectesc, setSelectesc] = useState([]);
    
    const [isFailed, setFailed] = useState(true);
    const navigate = useNavigate();
	const [song, setSong] = useState({
        title: "",
        authors:"",
        collaborators: [],
        track: "",
        demo_track: "",
        cover_file: "",
        perc_parti: "",
        folio_reg: "",
        requested: "",
        by_other: "",
        end_time_requested: "",
        observation_req: ""
    });

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed();
            setIsLoad(true);
        }, 200);
    }
	const handleSubmit = async (e) => {
        let button = e.currentTarget;
        try {
            e.preventDefault();
            if (song.requested != ""){
                let formData = new FormData();
                if (isAcc == 'Apartar_cero') {
                    formData.append('title', song.title);
                    formData.append('track', song.track);
                    formData.append('folio_reg', song.folio_reg);
                    formData.append('perc_parti', song.perc_parti);
                    formData.append('demo_track', song.demo_track);
                    if (selectes.length > 0) {
                        for (let i = 0; i < selectes.length; i++) {
                            formData.append('autors[]', selectes[i]);
                        }
                    }
                }
                if (selectesc.length > 0) {
                    for (let i = 0; i < selectesc.length; i++) {
                        formData.append('collaborators[]', selectesc[i]);
                    }
                }
                if(song.requested && song.requested.name){
                    formData.append('requested', song.requested._id);
                }else{
                    formData.append('requested', song.requested);
                }
                formData.append('by_other', song.by_other);
                formData.append('end_time_requested', song.end_time_requested);
                formData.append('observation_req', song.observation_req);
                formData.append('accion', isAcc);
                if (idSucursal){
                    formData.append('branch', idSucursal);
                }
                setLoading(true);
                setFailed(false);
                button.disabled = true;
                let Req;
                if(window.XMLHttpRequest) {
                    Req = new XMLHttpRequest();
                }
                Req.open("PUT", urlbase+"/app/update_song_apartada/"+cancionId, true);
                Req.setRequestHeader('Access-Control-Allow-Origin', '*');
                Req.setRequestHeader('Authorization', session.token);
                Req.responseType = 'json';
                Req.upload.onprogress = function (e) {
                    if (e.lengthComputable) {
                        var ratio = Math.floor((e.loaded / e.total) * 100);
                        setProgress(ratio);
                    }
                }
                Req.upload.onerror = function (e) {
                    setFailed(true);
                    console.log("** An error occurred during the transaction");
                };
                Req.onreadystatechange = async function() {
                    if (Req.readyState === 4) {
                        const res = Req.response;
                        const sgnDB = res.song;
                        if(res.clave && res.clave == "exito") {
                            await deleteSong(sgnDB._id+'Libre', sgnDB.title);
                            if (isAcc == 'Apartar_edit') {
                                setMessage({text: t('updated_song_aside'), type:"done"});
                                isComplete();
                            }else{
                                if (isAcc == 'Apartar_cero') {
                                    if (takeDown){
                                        const blob = new Blob([song.demo_track], { type: song.demo_track.type });
                                        await saveToIndexedDB(sgnDB._id+'Libre', song.title, 'Apartada', blob);
                                    }
                                    setMessage({text: t('song_added_successfully'), type:"done"});
                                    isComplete();
                                }else{
                                    setMessage({text: t('song_successfully_separated'), type:"done"});
                                    setTimeout(function() {
                                        navigate("/app/catalogo/apartadas");
                                    }, 1000);
                                }
                            }
                            setLoading(false);
                            button.disabled = false;
                            setOpen(false);
                            setSong({ title: "", authors:"", by_other: "", track: "", state: "Apartado", creation_date: new Date(), song_lyrics: "", cover_file: "", end_time_requested: "", observation_req:"",});
                            window.scrollTo(0,0);
                            setIsLoad(true);
                            handleClosed();
                        }else{
                            setAlert(res.mensaje);
                            setLoading(false);
                            button.disabled = false;
                        }
                    }
                }
                if (isAcc == 'Apartar_cero' && song.demo_track == '') {
                    if (song.title != '') {
                        setAlert(t('you_must_load_the_demo_track'));
                        setLoading(false);
                        button.disabled = false;
                    }else{
                        setAlert(t('you_must_add_a_creation_title'));
                        setLoading(false);
                        button.disabled = false;
                    }
                }else{
                    Req.send(formData);
                }
            }else{
            	setAlert(t('you_must_choose_an_artist'));
            }
        } catch (err) {
            console.log(err);
            setAlert(t('server_error'));
            setLoading(false);
            button.disabled = false;
        }
    }
    const loadSong = async (id) => {
    	const res = await fetch(urlbase+"/app/get_song/"+id, {headers:myHeaders()});
    	const response = await res.json();
    	const data = response.song;
        const clientsdb = response.clients;
        if (isAcc == 'Apartar_edit') {
            setSong({ title: data.title, collaborators:data.collaborators, by_other: data.by_other, requested:data.requested, end_time_requested:data.end_time_requested, observation_req:data.observation_req, coauthor:data.coauthor, folio_reg:data.folio_reg, state: data.state});
            if (data.collaborators && data.collaborators.length > 0){
                const array = await data.collaborators.map(function(item) {
                    return item['_id'];
                });
                await setSelectesc(array);
            }
        }else{
            await setSong({ title: data.title, by_other: "", requested:idClient ? idClient : '', coauthor:data.coauthor, end_time_requested: "", observation_req:"", folio_reg:data.folio_reg, state: data.state});
        }
        if (data.cover_file) {
            setImage(urlbase+data.cover_file);
        }
        if (clientsdb && clientsdb.length > 0) {
            setClients(clientsdb);
        }
        setIsLoad(false);
    }
    const loadAuthors = async (id) => {
    	const res = await fetch(urlbase+"/app/get_authors", {headers:myHeaders()});
    	const response = await res.json();
    	const data = response;
        setAuthors(data);
    }
    const loadCollaborators = async (id) => {
    	const res = await fetch(urlbase+"/app/get_collaborators", {headers:myHeaders()});
    	const response = await res.json();
    	const data = response;
        setCollaborators(data);
    }
    const loadInitsSong = async (id) => {
    	const res = await fetch(urlbase+"/app/get_clients", {headers:myHeaders()});
    	const clientsdb = await res.json();
        if (clientsdb && clientsdb.length > 0) {
            setClients(clientsdb);
        }
        setIsLoad(false);
    }
    const handleReload = (e) => {
        setProgress(0);
        handleSubmit(e);
    }
    const handleChange = (e) => {
        setSong({...song, [e.target.name]: e.target.value});
        setAlert(null);
    }
    const handleMulti = async (e) => {
        setSong({...song, ['requested']: e.value});
    }
    const handleMp3 = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['demo_track']: e.target.files[0]});
        }else{
            setSong({...song, ['demo_track']: null});
        }
    }
    const handleTrack = (e) => {
        if (e.target.files[0]) {
            setSong({...song, ['track']: e.target.files[0]});
        }else{
            setSong({...song, ['track']: null});
        }
    }
    const handleDown = (e) => {
        if (e.currentTarget) {
            setTakeDown(e.currentTarget.checked);
        }
    }
    const handleMultiA = (e) => {
        let arr = [];
        if (e.length > 0) {
            arr = e.map(function(item) {
                return item['value'];
            });
        }else{
            arr = [];
        }
        setSelectes(arr);
        setAlert(null);
    }
    const handleMultic = (e) => {
        let arr = [];
        if (e.length > 0) {
            arr = e.map(function(item) {
                return item['value'];
            });
        }else{
            arr = [];
        }
        setSelectesc(arr);
        setAlert(null);
    }
    

    useEffect(() => {
        if (open) {
            setClients([]);
            setSong({ title: "", authors:"", requested: "", by_other: "", track: "", demo_track: "", perc_parti: "", folio_reg: "", creation_date: new Date(), song_lyrics: "", cover_file: "", end_time_requested: "", observation_req:""});
            setAlert(null);
            if (cancionId != null){
                setIsLoad(true);
                loadSong(cancionId);
                loadCollaborators();
            }else{
                loadInitsSong();
                setIsLoad(false);
                setImage("/images/caratula.jpg");
                loadAuthors();
                loadCollaborators();
            }
        }
    }, [open]);

    if (!online) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <NotConection handleClosed={handleBack}/>
            </div>
        </div>
    </div>;


    if (isload) return <div className="fadeIn">
        <div className="py-2"></div>
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressModal />
            </div>
        </div>
    </div>;


	return (
		<div>
            <div className="head_modal justify-items-center">
                <button className='btn_back waves-effect text-center' onClick={handleBack}>
                    <IoChevronBack className='icon-md' />
                </button>
                <h5 className="modal-tittle">{isAcc == 'Apartar' ? t('set_aside_song') : isAcc == 'Apartar_edit' ? t('edit_set_aside') : isAcc == 'Apartar_cero' ? t('create_and_set_aside') : '-'}</h5>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <div className="modal_body">
                {isAcc == 'Apartar_cero' ? <div className="row gap-1 w-100 mb-2">
                    <div className='row gap-1 w-100'>
                        <div className="col-1">
                            <IoSettingsSharp className="icon-md"/>
                        </div>
                        <div className="col text-left">
                            <strong className="tag_inl clear-my text-uppercase sm-text is_link">{t('creation_data')}</strong>
                        </div>
                    </div>
                    <div className="row border-form p-2 w-100">
                        <div className="row w-100">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>{t('title')}</span>
                                    <span className='tg-requir'>{t('required')}</span>
                                </div>
                            </label>
                            <input type="text" className="inp_add in-requi" value={song.title} onChange={handleChange} name="title" placeholder={t('original_title')} autoComplete="off"/>
                        </div>
                        <div className="row w-100 my-1">
                            <label className="lb-inp-usm mb-1">{t('author')}</label>
                            <Select className='multi_sel w-100'
                                name="autors"
                                placeholder={t('selecting_authors')}
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                onChange={handleMultiA}
                                options={authors.map((author, ind) => {
                                    return {value:author._id, label:author.stage_name}
                                })}
                            />
                        </div>
                        <div className="row w-100 ">
                            <label className="lb-inp-usm mb-1">{t('audio_track')}</label>
                            <input type="file" className="inp_add" onInput={handleTrack} name="track" accept=".mp3,.m4a,.wav,audio/"/>
                        </div>
                        <div className="row gap-1 w-100 my-1">
                            <div className="col">
                                <label className="lb-inp-usm mb-1">{t('registration_folio')}</label>
                                <input type="text" className="inp_add text-uppercase" value={song.folio_reg} onChange={handleChange} name="folio_reg" placeholder="FL656556565655" autoComplete="off"/>
                            </div>
                            <div className="col-5">
                                <label className="lb-inp-usm mb-1">{t('participation')}</label>
                                <input type="number" min="1" className="inp_add" value={song.perc_parti} onChange={handleChange} name="perc_parti" placeholder="50%" autoComplete="off"/>
                            </div>
                        </div>
                        <div className="row w-100">
                            <label className="lb-inp-usm mb-1 w-100">
                                <div className='row w-100 justify-between'>
                                    <span>{t('demo_track')}</span>
                                    <span className='tg-requir'>{t('required')}</span>
                                </div>
                            </label>
                            <input type="file" className="inp_add in-requi" onInput={handleMp3} name="demo_track" accept=".mp3,.m4a,.wav,audio/"/>
                        </div>
                    </div>
                </div> : ''}
                <div className="row gap-1 w-100">
                    {isAcc == 'Apartar' || isAcc == 'Apartar_edit' ? <div className="row w-100">
                        <label className="lb-inp-usm mb-1">{t('track')}</label>
                        <a className="tag-phone f_imp row justify-between justify-items-center w-100">
                            <div className="col sm-col-12">
                                <img src={image != '/images/caratula.jpg' ? image : urlbase+'/images/caratula.jpg'} className="img-profile-sm sm-img-med mt-p3 mr-1" />
                                <p className="tag_inl inl_bk_sm clear-my">
                                    <div className='is_link'>{limitText(song.title, 20)}</div>
                                    <div className='sm-text-75 text-secondary'>{song.folio_reg}</div>
                                </p>
                            </div>
                        </a>
                    </div>: ''}
                    <div className="row w-100 justify-center py-2">
                        <label className="lb-inp-usm mb-1 w-100">
                            <div className='row w-100 justify-between'>
                                <span>{t('client_it_aside')}</span>
                                <span className='tg-requir'>{t('required')}</span>
                            </div>
                        </label>
                        <div className='position-relative w-100'>
                            <Select className='multi_sel'
                                name="requested"
                                placeholder={t('choose')}
                                isClearable={true}
                                isSearchable={true}
                                onChange={handleMulti}
                                defaultValue={song.requested && song.requested.name ? {value: song.requested._id, label:song.requested.name} : ''}
                                options={clients.map((client, ind) => {
                                    return {value:client._id, label:client.name}
                                })}
                                />
                        </div>
                    </div>
                    <div className="row w-100">
                        <label className="lb-inp-usm mb-1">Colaborador</label>
                        {song.collaborators && song.collaborators.length > 0 ? <Select className='multi_sel w-100 aqui'
                            name="collaborators"
                            placeholder={t('choose')}
                            isClearable={true}
                            isSearchable={true}
                            isMulti
                            onChange={handleMultic}
                            defaultValue={song.collaborators.map((sng) => {return {value: sng._id, label:sng.name}})}
                            options={collaborators.map((stdy, ind) => {
                                return {value:stdy._id, label:stdy.name}
                            })}
                        /> : <Select className='multi_sel w-100 dos'
                                name="collaborators"
                                placeholder={t('choose')}
                                isClearable={true}
                                isSearchable={true}
                                isMulti
                                onChange={handleMultic}
                                options={collaborators.map((stdy, ind) => {
                                    return {value:stdy._id, label:stdy.name}
                                })}
                        />}
                        <p className="py-1 sm-text color-secondary">El colaborador podra ver la cancion en su app de songor</p>
                    </div>
                    <div className="row w-100">
                        <label className="lb-inp-usm mb-1">{t('reserved_by')}: </label>
                        <input type="text" className="inp_add" value={song.by_other} onChange={handleChange} name="by_other" placeholder={t('another_name')} autoComplete="off"/>
                    </div>
                    <div className="row w-100">
                        <label className="lb-inp-usm mb-1">{t('deadline_for_reservations')}</label>
                        <div className='position-relative w-100'>
                            <input type="date" className="inp_add inpd_m text-left" value={extractDate(song.end_time_requested)} onInput={handleChange} name="end_time_requested"/>
                            <IoToday className="icn_fl_sel icon-sm showMovil" />
                        </div>
                    </div>
                </div>
                {alert !== null ? (<div className="row my-1">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
            </div>
            <div className='w-100 py-3'></div>
            <div className='w-100 py-3'></div>
            <div className="modal_footer">
                <div className="d-flex justify-end gap-2">
                    {isAcc == 'Apartar_cero' ? <div>
                        <label className={takeDown ? "custom-checkbox custom-is_check" : "custom-checkbox"} for='downloadCheckbox'>
                            <input type="checkbox" id="downloadCheckbox" onChange={handleDown} />
                            <span className="spn_chk">{t('download_song')}</span>
                            {takeDown ? <IoArrowDownCircleSharp className="icon-download" /> : <IoArrowDownCircleOutline className="icon-download" />}
                        </label>
                    </div> : ''}
                    <div>
                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>{loading ? t('uploading'): isAcc == 'Apartar_edit' ? t('apply') : t('set_aside')}</button>
                    </div>
                </div>
            </div>
            { loading ? <div className='fixed-upload'>
                <div className='center-content'>
                    <div className="row w-100">
                        <div className='row w-100 text-center justify-center p-3'>
                            <IoCloudUploadOutline className="icon-upld-anim"/>
                        </div>
                        <div className='row w-100 p-2'></div>
                        <div className="row w-100 cont_downloaad">
                            <div className="img_inl text-center">
                                <img src={'/images/caratula.jpg'} className="img-profile-med" />
                            </div>
                            <div className="text_inl text-left">
                                <p className="tag_tt_song clear-my">{t('uploading')}</p>
                                <p className="stx_tt text-secondary sm-text clear-my mb-1">{t('audio_track_and_poster')}</p>
                            </div>
                            <div className="row w-100 text-center">
                                <div className='barr_gains showBarr w-100'>
                                    <div className={isFailed ? "line_progrs_int brr_red" : progress >= 100 ? "line_progrs_int brr_grren" : "line_progrs_int"} style={{width:progress+'%'}}></div>
                                </div>
                            </div>
                            {isFailed ?  <span className="isDownloafail"><IoCloseCircle className="checkfail"/></span> : progress >= 100 ? <span className="isDownloaded">
                                <IoCheckmark className="checkisdone"/>
                            </span> : ''}
                            {progress < 100 ? <span className="isDownloaded"><div className="spinner_sm"></div></span> : ''}
                        </div>
                        {isFailed ? <div className={"d-flex mt-1 w-100 justify-between justify-items-center bg-danger-light rounded-4"}>
                            <p className="text-message p-2">{t('failed_uploading_files')}</p>
                            <IoRefreshCircle className="reload-button p-2 waves-effect waves-light" onClick={handleReload}/>
                        </div> : ''}
                        <div className='row w-100 text-center p-3'>
                            <p className="stx_tt text-secondary sm-text clear-my mb-1">{t('not_close_this_window')}</p>
                        </div>
                    </div>
                </div>
            </div> : ''}
        </div>
	)
}
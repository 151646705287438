import {useState, useEffect, useRef} from 'react'
import {IoEllipsisVerticalSharp, IoReorderThreeOutline, IoCreate, IoRemoveCircle, IoAddSharp, IoPlaySharp, IoRadioOutline, IoChevronBack, IoSend, IoFilterCircleOutline, IoRepeat} from "react-icons/io5";
import AlertDialog from "../../components/ui/AlertDialog";
import SnackBar from "../../components/ui/SnackBar";
import OptionMovil from "../../components/ui/OptionMovil";
import OptionCenter from "../../components/ui/OptionCenter";
import Modal from "../../components/ui/Modal";
import GrabadaForm from "../../components/forms/GrabadaForm";
import Song from "../../components/views/Song";
import Row from "../../components/ui/Row";
import ProgressIntern from "../../components/ui/ProgressIntern";
import {searchTable, extractDateR, limitText} from "../../components/utility/utils";
import DownloadFile from "../../components/ui/DownloadFile";
import ButtonDownload from "../../components/ui/ButtonDownload";
import FichaPrint from "../../components/ui/FichaPrint";
import FilterChoose from "../../components/ui/FilterChoose";
import AutorsField from "../../components/ui/AutorsField";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {useApp} from "../../context/appContext";
import { updateLocalGrabadas, getGrabadas } from "../../db";
import { useTranslation } from 'react-i18next';


export default function Grabadas({type}){
    const {online, position, play, setPlay, setPosition, playlist, setPlaylist, setLoading, urlbase, myHeaders} = useApp();
    const { t } = useTranslation();
    const [alert, setAlert ] = useState(null);
    const [message, setMessage] = useState(false);
    const [isload, setIsLoad] = useState(false);
    const [songs, setSongs] = useState([]);
    const [open, setOpen] = useState(false);
    const [option, setOption ] = useState(false);
    const [opens, setOpenes ] = useState(false);
    const [opend, setOpend] = useState(false);
    const [openp, setOpenp] = useState(false);
    const [idref, setIdRef] = useState(null);
    const [idclient, setIdClient] = useState(null);
    const [idopsel, setIdOpsel] = useState(null);
    const [idview, setIdView] = useState(null);
    const [opendown, setOpendown] = useState(false);
    const [songdown, setSongDown] = useState(null);
    const [openfil, setOpenfil] = useState(null);
    const [filter, setFilter] = useState('f_d');
    const [accion, setAccion] = useState('Grabar_edit');
    const tableSer = useRef(null);
    const section = 'Grabada';

    const handleBack = (e) => {
        setTimeout(() => {
            handleClosed(e);
        }, 200);
    }
    const abrirModal = () => {
        setAccion('Grabar_cero');
        setOpen(true);
    }
    const handleClosed = (e) => {
        setOpen(false);
        setOpend(false);
        setOpenes(false);
        setIdRef(null);
        setIdClient(null);
        setIdOpsel(null);
        setIdView(null);
        setAccion('Grabar_edit');
        setOpendown(false);
        setSongDown(null);
        setOpenp(false);
        e.stopPropagation();
    }
    const loadSongs = async (filter) => {
        if (online) {
            try {
                setIdRef(null);
                setIdClient(null);
                setLoading(true);
                let response = null;
                if (filter) {
                    response = await fetch(urlbase+"/app/get_songs/grabadas?filter="+filter, {
                        method: 'GET',
                        headers: myHeaders(),
                    })
                }else{
                    response = await fetch(urlbase+"/app/get_songs/grabadas", {
                        method: 'GET',
                        headers: myHeaders(),
                    })
                }
                const data = await response.json();
                setSongs(data);
                setIsLoad(true);
                setLoading(false);
                await updateLocalGrabadas(data);
                setAccion('Grabar_edit');
            } catch (err){
                console.log(err);
                console.warn('Fallo al obtener datos online, usando datos offline');
                const data = await getGrabadas();
                let shorts = await data.sort((a, b) => new Date(b.time_record) - new Date(a.time_record));
                setSongs(shorts);
                setIsLoad(true);
                setLoading(false);
            }
        } else {
            console.warn('Sin conexión, usando datos offline');
            const data = await getGrabadas();
            let shorts = await data.sort((a, b) => new Date(b.time_record) - new Date(a.time_record));
            setSongs(shorts);
            setIsLoad(true);
            setLoading(false);
        }
    }
    const handlePlay = async (e) => {
        if (songs.length > 0) {
            setPlaylist(songs);
            setPosition(Number(e.currentTarget.id));
            setPlay(true);
        }else{
            setMessage({text: "No hay canciones para reproducir", type:"error"});
        }
    }
    const handleFilter = async (e) => {
        setOpenfil(true);
    }
    const delClient = async (e, id) => {
        try {
            e.target.disabled = true;
            setLoading(true);
            const response = await fetch(urlbase+"/app/delete_song/"+idref, {
                method: 'DELETE',
                headers: myHeaders()
            })
            const data = await response.json();
            if (data.clave == "exito") {
                setSongs(songs.filter((songdb) => songdb._id !== idref));
                setMessage({text: "Cancion eliminada", type:"error"});
                setOpend(false);
                setIdRef(null);
            }else{
                setAlert(data.mensaje);
            }
            setLoading(false);
            e.target.disabled = false;
        } catch (err) {
            console.log(err);
            setLoading(false);
            e.target.disabled = false;
        }
    }
    const handleDelete = async (e) => {
        setIdRef(e.currentTarget.id);
        setOpend(true);
    }
    const handleSend = async (e) => {
        try {
            setIdRef(null);
            setIdClient(null);
            let id = e.currentTarget.id;
            let sg = null;
            let records = await songs.map((songdb) => {
                if(songdb._id == id){
                    sg = songdb;
                }
            });
            setSongDown(sg);
            setOpendown(true);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }
    const handleRegister = async (e) => {
        let id = e.currentTarget.id;
        var confirm = window.confirm("¿Modificar el registro de esta canción?");
        if (confirm) {
            try {
                e.target.disabled = true;
                setLoading(true);
                const response = await fetch(urlbase+"/app/update_reg_song/"+id, {
                    method: 'PUT',
                    headers: myHeaders()
                })
                const data = await response.json();
                if (data.clave == "exito") {
                    setSongs(songs.map((sng) => {
                        if (sng._id == data.song._id) {
                            sng.registered = data.song.registered;
                        }
                        return sng;
                    }));
                    setMessage({text: "Cancion actualizada", type:"done"});
                }else{
                    setMessage({text: data.mensaje, type:"error"});
                }
                setLoading(false);
                e.target.disabled = false;
            } catch (err) {
                console.log(err);
                setLoading(false);
                e.target.disabled = false;
            }
            
        }
    }
    const handleEdit = async (e) => {
        setIdClient(e.currentTarget.id);
        setOpen(true);
    }
    const handleView = async (e) => {
        setIdView(e.currentTarget.id);
        setOpenes(true);
    }
    const handleOptions = async (e) => {
        setIdOpsel(e.currentTarget.id);
        setIdRef(e.currentTarget.id);
        setIdClient(e.currentTarget.id);
        setOption(true);
    }
    const handleSearch = async (e) => {
        var inp = e.target;
        searchTable(tableSer.current, inp.value);
    }
    const handleSelect = async (e) => {
        e.target.select();
    }
    const moveRow = (fromIndex, toIndex) => {
        const updatedRows = [...songs];
        const [movedRow] = updatedRows.splice(fromIndex, 1);
        updatedRows.splice(toIndex, 0, movedRow);
        setSongs(updatedRows);
    };
    const updateOrder = async () => {
        if (filter == 'manual') {
            let OrdSongs = await songs.map((sg, ind) => {
                return {_id:sg._id, index:ind, title: sg.title}
            });
            let data = {};
            if (OrdSongs.length > 0) {
                data.songs = OrdSongs;
            }
            const response = await fetch(urlbase+"/app/update_order/grabadas", {
                method: 'PUT',
                headers: myHeaders(),
                body: JSON.stringify(data)
            })
            const res = await response.json();
            console.log(res);
        }
    };
    const handleFicha = async (e) => {
        setIdClient(e.currentTarget.id);
        setOpenp(true);
    }



    useEffect(() => {
        setSongs([]);
        setPlay(false);
        setMessage(false);
        loadSongs();
        setIsLoad(false);
    }, [type])

    useEffect(() => {
        if (option == false) {
            setIdOpsel(null);
        }
    }, [option])

    useEffect(() => {
        if (filter) {
            if (filter) {
                loadSongs(filter);
            }
        }
    }, [filter])


    if (!isload) return <div className="Isexpand w-100">
        <div className="rounded row w-100">
            <div className="row px-3 w-100">
                <ProgressIntern />
            </div>
        </div>
    </div>;

    return(
        <div className="fadeIn w-100">
            <div className="px-3">
                <div className="row justify-between justify-items-center sm-py-1">
                    <div className="col-5 col-sm-9">
                        <div className="row w-100 justify-items-center gap-2">
                            <div className="col">
                                <input type="search" className="inp_add" placeholder={t('search')} onInput={handleSearch} onClick={handleSelect}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 py-2 md-p-2 text-right">
                        <button className="btn-icon btn-primary waves-effect waves-light btn-flt-mov" onClick={abrirModal}>
                            <div className="row w-100 align-items-center">
                                <IoAddSharp className="col col-sm-12 icon-btn plus_btn mr-m ms-sm-rauto w-100" />
                                <span className="col col-sm-12 sm-txt-85">{t('recorded_s')}</span>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="row justify-between justify-items-center mb-2">
                    <div>
                        {filter && filter != '' ? <p className="text-filter"><span className="mx-1 text-uppercase">{t('filter')}: {filter == 'f_a' ? t('date_asc') : filter == 'f_d' ? t('date_desc') : filter.toUpperCase()}</span></p> : ''}
                    </div>
                    <a className="tag-filter" onClick={handleFilter}>
                        <span className="mx-1 phone-number text-uppercase">{t('filter_s')}</span>
                        <IoFilterCircleOutline className="icon-phone-tg"/>
                    </a>
                </div>
                <div className="row my-2">
                    <DndProvider backend={HTML5Backend}>
                        <table className="table_outline">
                            <thead>
                                <tr>
                                    {filter == 'manual' ? <th className="text-center">  </th> : ''}
                                    <th className="ocultar-sm">{t('recording_date')}</th>
                                    <th className="text-center"><span className="ocultar-sm">Master</span> grb</th>
                                    <th>{t('title')}</th>
                                    <th th className="text-center">Reg.</th>
                                    <th className="ocultar-sm">{t('interpreter')}</th>
                                    <th className="ocultar-sm">{t('send')}</th>
                                    <th className="ocultar-sm">{t('contract_start')}</th>
                                    <th className="ocultar-sm">{t('contract_end')}</th>
                                    <th className="text-center">Acc</th>
                                </tr>
                            </thead>
                            <tbody ref={tableSer}>
                                {songs.map((song, ind) => {
                                    return(<Row song={song} index={ind} moveRow={filter == 'manual' ? moveRow : null} key={'t_u_'+ind} endDrag={updateOrder}>
                                        {filter == 'manual' ? <td className="text-center"><IoReorderThreeOutline className="icon_move" /></td> : ''}
                                        <td className="ocultar-sm"><span className="sm-text color-secondary">{extractDateR(song.time_record)}</span></td>
                                        <td className="text-center">
                                            <button className="btn btn-sm btn_play waves-effect is_grab" id={ind+1} onClick={handlePlay}>{position == ind+1 && play == true && playlist[position-1].state == section ? <IoRadioOutline className="icon-ply pulse" /> : <IoPlaySharp className="icon-ply" />}</button>
                                        </td>
                                        <td>
                                            <div className="row gap-1 justify-items-center">
                                                <div className="col-4 col-sm-2 sm-text-center">
                                                    <img src={song.cover_file != null && song.cover_file != 'null' ? urlbase+song.cover_file : urlbase+'/images/caratula.jpg'} className="img-profile-sm" />
                                                    <ButtonDownload songId={song._id} title={song.title} state={song.state} setMessage={setMessage}/>
                                                </div>
                                                <div className="col text-left">
                                                    <p className="tag_inl is_link clear-my">
                                                        <span id={song._id} onClick={handleView}> {song.title} </span>
                                                    </p>
                                                    <p className="stx_tt text-secondary sm-text showMovil clear-my">{song.interpreter ? song.interpreter.name : "-"}</p>
                                                    <p className="text clear-my showMovil">
                                                        {song && song.collaborators && song.collaborators.length > 0 ? <IoRepeat className='is_link_purple sz-shar-icn' /> : ''}
                                                        <AutorsField autors={song && song.autors ? song.autors : []} />
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <button className={song.registered ? "tag-reg tag-reg-ok" : "tag-reg"} target="_blank" id={song._id} onClick={handleRegister}>
                                                <span className='fs-15'>®</span>
                                            </button>
                                        </td>
                                        <td className="ocultar-sm">
                                            <img src={song.interpreter && song.interpreter.photo != null && song.interpreter.photo != '' &&  song.interpreter.photo != 'null' ? urlbase+song.interpreter.photo : urlbase+'/images/usuario.png'} className="img-profile-sm mr-1" />
                                            <p className="tag_inl is_link clear-my">{song.interpreter ? song.interpreter.name : "-"}</p>
                                        </td>
                                        <td className="ocultar-sm">
                                            <button className="btn btn-sm btn-outline-primary" data-name={song.title} id={song._id} onClick={handleSend}>
                                                <div className="row w-100 align-items-center">
                                                    <IoSend className="icon-btn mr-m" />
                                                    <span className="col">{t('send')}</span>
                                                </div>
                                            </button>
                                        </td>
                                        <td className="ocultar-sm">
                                            <span className="sm-text color-secondary">{song.type_date_contract == 'Infinita' ? '∞': extractDateR(song.contract_date_start)}</span>
                                        </td>
                                        <td className="ocultar-sm">
                                            <span className="sm-text color-secondary">{song.type_date_contract == 'Infinita' ? '∞': extractDateR(song.contract_date_end)}</span>
                                        </td>
                                        <td className="text-center">
                                            <button className="btn btn-sm btn-outline-primary mx-1 ocultar-sm" id={song._id} onClick={handleEdit}>{t('edit')}</button>
                                            <button className="btn btn-sm btn-outline-danger mr-1 ocultar-sm" id={song._id} onClick={handleDelete}>{t('delete')}</button>
                                            <button className="btn btn-sm btn-outline-danger ocultar-sm" id={song._id} onClick={handleFicha}>{t('file')}</button>
                                            <button className="btn btn-sm btn-outline-transparent waves-effect show-sm" id={song._id} onClick={handleOptions}>
                                                <IoEllipsisVerticalSharp className="icon-opc"/>
                                            </button>
                                        </td>
                                    </Row>)
                                })}
                            </tbody>
                        </table>
                    </DndProvider>
                </div>
            </div>
            <div className='w-100 sm-py-2'></div>
            <div className='w-100 sm-py-2'></div>
            <Modal open={openp} setOpen={setOpenp} size={"modal-md"} height={"hg-auto"} handleClosed={handleClosed}>
                <FichaPrint open={openp} setOpen={setOpenp} songId={idclient} setMessage={setMessage} estado={'Grabada'}/>
            </Modal>
            <OptionCenter open={openfil} setOpen={setOpenfil} setIdClient={setIdClient}>
                <FilterChoose open={openfil} setOpen={setOpenfil} filter={filter} setFilter={setFilter} />
            </OptionCenter>
            <Modal open={opendown} setOpen={setOpendown} size={"modal-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <DownloadFile open={opendown} setOpen={setOpendown} songref={songdown} setMessage={setMessage}/>
            </Modal>
            <Modal open={opens} setOpen={setOpenes} size={"modal-md"} height={"hg-auto"} handleClosed={handleClosed}>
                <Song open={opens} setOpen={setOpenes} songId={idview} setOpenp={setOpenp} setIdClient={setIdClient} estado={'Grabada'}/>
            </Modal>
            <Modal open={open} setOpen={setOpen} size={"modal-md"} height={"hg-auto"} handleClosed={handleClosed}>
                <GrabadaForm open={open} setOpen={setOpen} isComplete={loadSongs} isEdit={true} isAcc={accion} cancionId={idclient} setMessage={setMessage} handleClosed={handleClosed}/>
            </Modal>
            <Modal open={opend} setOpen={setOpend} size={"modal-u-sm"} height={"hg-auto"} handleClosed={handleClosed}>
                <div className="head_modal justify-items-center">
                    <button className='btn_back waves-effect text-center' onClick={handleBack}>
                        <IoChevronBack className='icon-md' />
                    </button>
                    <h5 className="modal-tittle">{t('delete_this_song')}</h5>
                </div>
                <div className='w-100 sm-py-2'></div>
                <div className='w-100 sm-py-2'></div>
                <div className="modal_body text-center sm-text-left">
                    <p className="color-red">{t('this_song_will_be_removed')}</p>
                </div>
                {alert !== null ? (<div className="row my-2">
                    <AlertDialog mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                </div>) : ''}
                <div className='w-100 py-3'></div>
                <div className='w-100 py-3'></div>
                <div className="modal_footer">
                    <div className="d-flex justify-end">
                        <div>
                            <button className="btn btn-danger waves-effect waves-light" onClick={delClient}>{t('delete')}</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <OptionMovil open={option} setOpen={setOption} setIdClient={setIdClient}>
                <ul className="list">
                    <div className="item-list waves-effect" id={idclient} onClick={handleSend}>
                        <div className="cont-icon">
                            <IoSend className="icon_item"/>
                        </div>
                        <span className="tex_item">{t('send')}</span>
                    </div>
                    <div className="item-list waves-effect" id={idclient} onClick={handleEdit}>
                        <div className="cont-icon">
                            <IoCreate className="icon_item"/>
                        </div>
                        <span className="tex_item">{t('edit')}</span>
                    </div>
                    <div className="item-list waves-effect" id={idref} onClick={handleDelete}>
                        <div className="cont-icon">
                            <IoRemoveCircle className="icon_item"/>
                        </div>
                        <span className="tex_item">{t('delete')}</span>
                    </div>
                </ul>
            </OptionMovil>
            {message ? <SnackBar text={message.text} setMessage={setMessage} type={message.type} close={false}/> : ''}
        </div>
    )
}
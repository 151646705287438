import { useState, useEffect } from "react";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import {useApp} from "../../context/appContext";
import { saveToIndexedDB, getSongFromIndexedDB, deleteSong } from "../../db";
import { useTranslation } from 'react-i18next';

export default function ButtonDownload({songId, title, state, setMessage}) {
    const {online, session, urlbase, myHeaders} = useApp();
    const { t } = useTranslation();
    const [downloading, setDownloading] = useState(false);
    const [isDownload, setIsDonload] = useState(false);

    const handleDownload = async (e) => {
        if (!isDownload) {
            if (online) {
                try {
                    // Llamar a la función de descarga (descripción más adelante)
                    const blob = await downloadFile(songId, urlbase+"/app/get_file/"+songId);
                    // // Guardar en IndexedDB
                    await saveToIndexedDB(songId+state, title, state, blob);
                } catch (error) {
                    setMessage({text: t('error_downloading_file'), type:"error"});
                    console.error("Error al descargar la canción:", error);
                } finally {
                    setDownloading(false);
                }
            }else{
                setMessage({text: t('no_connection'), type:"error"});
            }
        }else{
            let conf = window.confirm(t('delete_download')+" - "+title+"-?");
            if (conf) {
                let armId = songId+state;
                if (state == 'Apartada') {
                    armId = songId+'Libre';
                }
                deleteSong(armId, title);
                setMessage({text: t('download_deleted'), type:"error"});
                setIsDonload(false);
            }
        }
    }
    const downloadFile = (id, url) => {
        return new Promise((resolve, reject) => {
            try {
                setDownloading(true);
                setTimeout(function() {
                    let Req;
                    if(window.XMLHttpRequest) {
                        Req = new XMLHttpRequest();
                    }
                    Req.open("GET", url, true);
                    Req.responseType = "blob";
                    Req.setRequestHeader('Access-Control-Allow-Origin', '*');
                    Req.setRequestHeader('Authorization', session.token);
                    Req.onprogress = function (e) {
                        if (e.lengthComputable) {
                            var ratio = Math.floor((e.loaded / e.total) * 100);
                        }
                    }
                    Req.onerror = function (e) {
                        // setFailed(true);
                        console.log("** An error occurred during the transaction");
                    };
                    Req.onload = () => {
                        if (Req.status === 200) {
                            resolve(Req.response);
                            setIsDonload(true);
                        } else {
                            reject(new Error("Error al descargar el archivo."));
                        }
                    };
                    Req.send();
                }, 5000);
            } catch (err) {
                console.log(err);
            }
        });
    };

    
    useEffect(async () => {
        if (songId) {
            let armId = songId+state;
            if (state == 'Apartada') {
                armId = songId+'Libre';
            }
            const songBlob = await getSongFromIndexedDB(armId);
            if (songBlob) {
                setIsDonload(true);
            }
        }
    }, [songId]);




    return(
        <>
        {downloading ? 
            <div className="spinner_download"></div> 
        : 
            <button className='btn_donload_sm' id={songId} onClick={handleDownload} disabled={downloading}>
                <IoArrowDownCircleOutline className={isDownload ? "form-circ isDown" : "form-circ"} />
            </button>
        }
    </>
    )
    
}



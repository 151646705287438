import { Link } from "react-router-dom";
import { IoSettings, IoCaretDown, IoCameraReverse, IoPowerSharp, IoPeople } from "react-icons/io5";
import { useEffect, useState } from "react";
import { StatusBar } from '@capacitor/status-bar';
import {useApp} from "../../context/appContext";
import { useTranslation } from 'react-i18next';

export default function Header() {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const {ncoll, online, clearSession, urlbase, loadSessionToken} = useApp();
    const [openLogout, setOpenLogout] = useState(false);
    const [showintro, setShowIntro ] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [user, setUser] = useState({name:"", rol:"", photo:''});

    const handleMenuLogout = (e) => {
        e.stopPropagation();
        if (openLogout)
            setOpenLogout(false);
        else
            setOpenLogout(true);
    }
    const handleIdioma = (e) => {
        let lng = e.currentTarget.value;
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
    }
    const handleLogout = (e) => {
        e.stopPropagation();
        setShowIntro(true);
        updateStatusBarColor('#ffffff');
        setTimeout(async () => {
            setTimeout(function(argument) {
                clearSession();
            }, 200);
        }, 1500);
    }
    const updateStatusBarColor = (color) => {
        // Cambia la metaetiqueta theme-color
        document.querySelector("meta[name=theme-color]")?.setAttribute("content", color);
        // Cambia el color de la barra de estado en Capacitor
        StatusBar.setBackgroundColor({ color: color });
    };
   

    useEffect(() => {
        const session = loadSessionToken();
        let foto = '/images/usuario.png';
        if (session.user.photo) {
            foto = urlbase+session.user.photo;
        }
        setUser({name:session.user.name, rol:session.user.rol, photo:foto, study: session.user.study});
        const onClick = () => {
            let mn = document.getElementById("menu_slide");
            setOpenLogout(false);
            setOpenMenu(false);
            mn.classList.remove('show');
            mn.classList.remove('bounceInLeft');
        };
        document.addEventListener("click", onClick);
        return () => {
            document.removeEventListener("click", onClick);
        };
    }, [])

    return(
        <header className="header">
            <Link className="cont_logo position-relative" to="/app">
                <img src="/images/logo_hori.png" className="logo_empresa ocultar-sm"/>
                <img src="/images/logo_iso.png" className="logo_empresa_mov showMovil"/>
                <span className={online ? 'isOnline Ogreen' : 'isOnline Ored'}></span>
            </Link>
            <div className="cont-study ocultar-sm">
                <img src="/images/study.jpg" className="img_study ocultar-sm"/>
                <span to="#" className="nombre_user"> 
                    <p className="tp_study text-uppercase">{user.study ? user.study.name : ''}</p>
                    <p className="tp_study_nm">{user.study ? user.study.city : ''}, Study</p>
                </span>
            </div>
            <div className="user_opc_cont">
                <div className="cont-hd-right ocultar-sm">
                    <label className="row is_con_sel align-items-center p-1 mr-2" htmlFor="language">
                        <div className="col-2 mr-2">
                            <img src={currentLanguage == 'es' ? "/images/b_mex.jpg" : "/images/b_usa.png"} alt="" className="img-profile-sm"/>
                        </div>
                        <select className="col sl-trans" id="language" onChange={handleIdioma}>
                            <option value="es" selected={currentLanguage == 'es' ? true : false}>Español</option>
                            <option value="en" selected={currentLanguage == 'en' ? true : false}>Ingles</option>
                        </select>
                    </label>
                </div>
                <Link className="cont-btns-hd" to="/app/collaborators">
                    <IoPeople className="icon_friends"/>
                    {ncoll > 0 ? <span className="tag-number">{ncoll}</span> : ''}
                </Link>
                <span className="user_opc"  onClick={handleMenuLogout}>
                    <span to="#" className="nombre_user"> {user.name} <p className="tp_almn">{user.rol}</p></span>
                    <div className="list_p">
                        <img src={user.photo} className="img_user" id="avatar-app"/>
                    </div>
                    <IoCaretDown className="icon_Sa"/>
                </span>
            </div>
            <div className={openLogout ? 'menu_salir' : 'menu_salir dnone'}>
                <div className="list_opc">
                    <Link to={user.rol != 'Redactor' ? '/app/foto_perfil' : '/editor/foto_perfil'} className="item_opc_salir waves-effect">
                        <span className="link_text">{t('change_profile_picture')}</span>
                        <IoCameraReverse className="icon_list"/>
                    </Link>
                    <Link to={user.rol != 'Redactor' ? '/app/configuraciones' : '/editor/configuraciones'} className="item_opc_salir waves-effect">
                        <span className="link_text">{t('settings')}</span>
                        <IoSettings className="icon_list"/>
                    </Link>
                    <div className="item_opc_salir waves-effect" onClick={handleLogout}>
                        <span className="link_text">{t('logout')}</span>
                        <IoPowerSharp className="icon_list"/>
                    </div>
                </div>
            </div>
            {showintro == true ? <div className="fixed-login-content Isexpand">
                <div className='center-content text-center'>
                    <img className="img_tit_log" src="/images/icon.png" />
                    <div className="row w-100 text-center justify-center py-1">
                        <div className="sippiner"></div>
                    </div>
                    <span>{t('logging_out')}...</span>
                </div>
            </div>: ''}
        </header>
    )
}
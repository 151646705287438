import { useNavigate, useParams } from "react-router-dom";
import {useRef, useState, useEffect} from 'react'
import {useApp} from "../context/appContext";
import { StatusBar } from '@capacitor/status-bar';
import { useTranslation } from 'react-i18next';
import StepItem from "../components/ui/StepItem";
import AlertDialog from "../components/ui/AlertDialog";
import InputWarning from "../components/ui/InputWarning";
import {updateThemeColor, validPassword} from "../components/utility/utils";
import {IoChevronBack, IoLockClosedOutline, IoEye, IoCheckmarkSharp} from "react-icons/io5";

export default function ForgotPassword() {
    const {loading, setLoading, urlbase, myHeaders, loadSessionToken} = useApp();
    const { t } = useTranslation();
    const[portal, setPortal] = useState("login");
    const [alert, setAlert ] = useState(null);
    const [isvalid, setIsValid ] = useState("invalid");
    const [user, setUser ] = useState({
        token: '',
        user:'',
        password:'',
        c_password: ''
    });
    const { token } = useParams();
    const inputPass = useRef(null);
    const inputPassc = useRef(null);
    const navigate = useNavigate();


    const handleForgot = async (e) => {
        if (validPassword(user.password) && validPassword(user.c_password) && user.password == user.c_password) {
            let button = e.currentTarget;
            try {
                e.preventDefault();
                button.disabled = true;
                setLoading(true);
                let response = await fetch(urlbase+"/update-new-password", {
                    method: 'POST',
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(user)
                });
                const res = await response.json();
                if (res.clave == 'exito') {
                    setTimeout(() => {
                        setIsValid("complete");
                    }, 1000);
                }else{
                    if (res.message == 'Token inválido') {
                        setAlert(t('invalid_link'));
                    }else if (res.message == 'La contraseña debe tener al menos 8 caracteres') {
                        setAlert(t('min_password_character'));
                    }else {
                        setAlert(t('server_error'));
                    }
                    setAlert(res.message);
                }
                setLoading(false);
                button.disabled = false;
            } catch(e) {
                // statements
                console.log(e);
                setAlert(t('server_error'));
                button.disabled = false;
                setLoading(false);
            }
        }else {
            setAlert(t('password_no_match'));
        }
    }
    const verfiqToken = async () => {
        try {
            if (token) {
                setLoading(true);
                const response = await fetch(urlbase+"/verfiq-token/"+token, {
                    method: 'GET'
                })
                const data = await response.json();
                if (data.clave == 'exito') {
                    setIsValid("valid");
                    console.log(token);
                    setUser({...user, ['token']: token});
                }else if (data.clave == 'utilizado') {
                    setIsValid("expire");
                }else{
                    setIsValid("invalid");
                }
                setLoading(false);
            }
        } catch (err){
            console.log(err);
            setLoading(false);
        }
    }
    const handleChange = (e) => {
        if (validPassword(e.target.value)) {
            setAlert(null);
            setUser({...user, [e.target.name]: e.target.value});
        }else{
            setAlert(t('min_password_character'));
            setUser({...user, [e.target.name]: e.target.value});
        }
    }
    const updateStatusBarColor = (color) => {
        // Cambia la metaetiqueta theme-color
        document.querySelector("meta[name=theme-color]")?.setAttribute("content", color);
        // Cambia el color de la barra de estado en Capacitor
        StatusBar.setBackgroundColor({ color: color });
    };
    const handlePortal = () => {
        navigate("/");
    }
    const handleInput = (e) => {
        if(inputPass.current.type == "password"){
            inputPass.current.type = 'text';
            e.currentTarget.classList.add('blue-focus');
        }else{
            e.currentTarget.classList.remove('blue-focus');
            inputPass.current.type = 'password';
        }
    }
    const handleInputc = (e) => {
        if(inputPassc.current.type == "password"){
            inputPassc.current.type = 'text';
            e.currentTarget.classList.add('blue-focus');
        }else{
            e.currentTarget.classList.remove('blue-focus');
            inputPassc.current.type = 'password';
        }
    }

    updateStatusBarColor('#ffffff');

    useEffect(() => {
        verfiqToken();
    }, [token])

    useEffect(() => {
        console.log(user);
    }, [user])

    return (
        <div className="landing_ani fadeIn position-relative position-relative">
            <div className="contene_log">
                <div className="row content-central mt-3">
                    <div className="col col-md-12 position-relative hg-md-200 ocultar-sm">
                        <div className="opacy_login"></div>
                        <img src="/images/controles.jpg" className="bg-image" />
                    </div>
                    <div className="col col-md-12">
                        <div className="cont_signup ">
                            <div className="row justify-between align-items-center cnt_inp">
                                <button className="btn-link-lg" onClick={handlePortal}>
                                    <div className="row align-items-center">
                                        <IoChevronBack className="lg-icn-face" />
                                        {t('login')}
                                    </div>
                                </button>
                                <div className="text-secondary">
                                    <StepItem check={false}/>
                                </div>
                            </div>
                            <figure className="cont_img m-w-300">
                                <img className="img_sing_log" src="/images/logo_iso.png" />
                                <h1 className="text-center sm-tt-forgot w-100">{t('reset_password')}</h1>
                                <div className="limpio n-vert-mg">
                                    {isvalid ? <p className="text-secondary">{t('enter_new_password')}</p> : ''}
                                </div>
                            </figure>
                            {isvalid == 'valid' ? <>
                                <div className="cnt_inp">
                                    <IoLockClosedOutline className="icon-inp"/>
                                    <input className="inp_login w-100" ref={inputPass} type="password" name="password" value={user.password} onChange={handleChange} placeholder={t('new_password')}/>
                                    <IoEye className="show-pass" onClick={handleInput}></IoEye>
                                </div>
                                <div className="cnt_inp">
                                    <IoLockClosedOutline className="icon-inp"/>
                                    <input className="inp_login w-100" ref={inputPassc} type="password" name="c_password" value={user.c_password} onChange={handleChange} placeholder={t('repeat_password')}/>
                                    <IoEye className="show-pass" onClick={handleInputc}></IoEye>
                                    {alert !== null || alert !== '' ? (<div className="row my-1">
                                        <InputWarning mensaje={alert} setMensaje={setAlert} time={3000} closed={false}/>
                                    </div>) : ''}
                                </div>
                                <div className="cnt_inp t_center">
                                    <button className="btn_login waves-effect button waves-light" id="btn_login" onClick={handleForgot} disabled={!validPassword(user.password) || !validPassword(user.c_password)}>
                                        <div className="row justify-center justify-items-center w-100">
                                            {loading == false ? <span className="fs-7 fw-500">{t('update_password')}</span> : ''}
                                            {loading ? <div className="mx-1 spinner_sm"></div> : ''}
                                        </div>
                                    </button>
                                </div>
                            </> : isvalid == 'invalid' || isvalid == 'expire' ? <>
                                <div className="limpio n-vert-mg m-w-300 text-center py-3">
                                    <p className="text-danger">{t('link_expire')}</p>
                                </div>
                                <div className="cnt_inp t_center">
                                    <button className="btn_login waves-effect button waves-light" onClick={handlePortal}>
                                        <div className="row justify-center justify-items-center w-100">
                                            <span className="fs-7 fw-500">{t('return_to_home')}</span>
                                        </div>
                                    </button>
                                </div>     
                            </> : <div className="m-w-300">
                                <div className="my-2 w-100 text-center">
                                    <IoCheckmarkSharp className="icon_done_large Isexpand"/>
                                </div>
                                <h3 className="mb-1 w-100 text-center Isexpand">{t('password_update')}</h3>
                                <p className="w-100 text-center Isexpand">{t('access_login')}</p>
                                <div className="my-3 w-100">
                                    <button className="btn_login waves-effect button waves-light Isexpand" onClick={handlePortal} disabled={loading}>
                                        {t('accept')}
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>  
                <div className="footer-login">
                    <div className="text-center text-secondary w-100">
                        <div>{t('term_and_conditions')}</div>
                        <div className="py-1 w-100 opc-3">{t('all_rights_reserved')} © songor {new Date().getFullYear()}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}